import React from 'react'
import { Alert, Box, Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { useContext } from 'react';
import { UserContext } from '../../../../../context/UserContext';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState } from 'react';
import { useEffect } from 'react';
import AdBoxForAdmin from '../../../../../components/AdBoxForAdmin';
import VideoPlayerWithThumbnail from '../../../../../components/VideoPlayerWithThumbnail';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

function RelatedAdsDialog({ targetAd, open, handleClose }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const [choosableAds, setChoosableAds] = useState([]);
    const [relatedAds, setRelatedAds] = useState([]);

    const [addRelatedAdDialogOpen, setAddRelatedAdDialogOpen] = useState(false);

    const fetchChoosableAds = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdsToAddAsRelated?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setChoosableAds(response.data.ads.filter(ad => ad.brand_id !== targetAd.brand_id && relatedAds.filter(relatedAd => relatedAd.id === ad.id).length === 0));
                }
            });
    }

    const fetchRelatedAds = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getRelatedAds?token=${token}&adId=${targetAd.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setRelatedAds((prev) => []);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setRelatedAds(response.data.ads);
                }
            });
    }

    const addToRelatedAds = (ad) => {
        axios.post(`${process.env.REACT_APP_API_URL}api/alcago/ad/createRelatedAd?token=${token}`, {
            relatedAd: {
                ad_id: targetAd.id,
                related_ad_id: ad.id
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchRelatedAds();
                    setAddRelatedAdDialogOpen(false);
                }
            });
    };

    const removeFromRelatedAds = (ad) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/ad/deleteRelatedAd?token=${token}&relatedAdId=${ad.id}&adId=${targetAd.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchRelatedAds();
                }
            });
    };

    useEffect(() => {
        fetchRelatedAds();
    }, [])

    useEffect(() => {
        fetchChoosableAds();
    }, [relatedAds])


    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md">
            <Dialog
                sx={{ margin: "0 auto" }}
                open={addRelatedAdDialogOpen}
                onClose={() => setAddRelatedAdDialogOpen(false)}
                fullWidth
                maxWidth="md">
                <Box minWidth="300px" m="20px" textAlign="center">
                    <Typography variant='h3'>
                        Reklam Ekle
                    </Typography>
                </Box>
                <DialogContent>
                    <Box display="flex" justifyContent="center" flexWrap={"wrap"}>
                        {choosableAds.map((ad) =>
                            <AdBoxForAdmin key={ad.id} ad={ad} handleAdSelection={() => {
                                addToRelatedAds(ad);
                            }} forChooseRelatedAds={true} />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>

            <Box minWidth="300px" m="20px" textAlign="center">
                <Typography variant='h3'>
                    Sıradaki Reklamlar
                </Typography>
            </Box>
            <DialogContent>
                <Box mt="10px">
                    {relatedAds.length === 0 &&
                        <Alert severity="info">
                            Sıradaki reklamlar otomatik olarak ayarlanmaktadır.
                        </Alert>}
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        {relatedAds.map((ad) =>
                            <Box key={ad.id}
                                width="60%"
                                m="10px"
                                sx={{ background: theme.palette.mode === 'dark' ? colors.grey[100] : "white", border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
                                <VideoPlayerWithThumbnail thumbnailLink={ad.thumbnail_image_link} videoLink={ad.video_link} />
                                <Box pb="10px" display="flex" flexDirection={"column"} justifyContent={"space-between"} >

                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box ml="20px" pt="10px" pb="10px" >
                                            <Typography variant='h3' color={colors.primary[900]}>{ad.brand_name}</Typography>
                                            <Typography variant='h5' color={colors.primary[700]}>Alt Reklam</Typography>
                                            <Typography variant='h6' color={colors.primary[700]}>Bu reklam kupon dağıtmamaktadır.</Typography>
                                            <Typography variant='h6' color={colors.primary[700]}>{`Gösterim Limiti: ${ad.display_limit === 0 ? "Limitsiz" : ad.display_limit}`}</Typography>
                                        </Box>
                                        <Box>
                                            <Box height="15%"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                textAlign="center"
                                                p="5px 10px 5px 10px"
                                                mr="10px"
                                                mb="20px"
                                                zIndex="10"
                                                sx={{ background: colors.greenAccent[700], borderRadius: "20px" }}>
                                                <RemoveRedEyeOutlined sx={{ color: colors.grey[100] }} />
                                                <Typography variant='h6' color={colors.grey[100]} ml="5px">{ad.watch_count}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box mt="10px" display="flex" justifyContent="center" gap="10px" px="5px">
                                        <Button variant='outlined' color='warning' onClick={() => {
                                            removeFromRelatedAds(ad);
                                        }}
                                            sx={{
                                                height: "35px",
                                                borderColor: colors.redAccent[700],
                                                color: colors.redAccent[700]
                                            }}>
                                            Kaldır
                                        </Button>
                                    </Box>

                                </Box>

                            </Box>
                        )}
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" rowGap="10px" mt="20px">
                        {
                            relatedAds.length >= targetAd.releated_ad_count &&
                            <Alert severity="info">
                                Ayarlanmış sıradaki reklam sayısına ulaşıldı. Yenisini eklemek için bir reklamı kaldırınız.
                            </Alert>
                        }
                        <Button onClick={() => setAddRelatedAdDialogOpen(true)} variant="contained" color="success" disabled={relatedAds.length >= targetAd.releated_ad_count}>Reklam Ekle</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default RelatedAdsDialog;