import { Box, Button, Checkbox, Typography, useTheme } from '@mui/material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import React from 'react'
import { tokens } from '../theme';
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import VideoPlayerWithThumbnail from './VideoPlayerWithThumbnail';

function AdBoxForAdmin({ ad, handleAdSelection, handleRelatedAdsAddition, handleDisplayLimitChange, forChooseRelatedAds }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const navigate = useNavigate();

    const [featured, setFeatured] = useState(ad.is_featured === 1);
    const [featuredWalletPage, setFeaturedWalletPage] = useState(ad.is_featured_wallet_page === 1);
    const [hover, setHover] = useState(false);

    const handleFeatured = (adModel) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/updateAd?token=${token}&adId=${ad.id}`, {
            ad: {
                ...adModel
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (adModel.is_featured !== undefined && adModel.is_featured !== null) {
                        setFeatured((prev) => !prev);
                    } else if (adModel.is_featured_wallet_page !== undefined && adModel.is_featured_wallet_page !== null) {
                        setFeaturedWalletPage((prev) => !prev);
                    }
                }
            });
    }

    return (
        <Box key={ad.id}
            width={forChooseRelatedAds ? "40%" : "23%"}
            display="inline-block"
            m="10px"
            sx={{ background: colors.grey[100], border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <VideoPlayerWithThumbnail thumbnailLink={ad.thumbnail_image_link} videoLink={ad.video_link} />
            <Box
                pb="10px"
                display="flex" flexDirection={"column"} justifyContent={"space-between"}
                sx={hover ? {
                    backgroundColor: theme.palette.mode === 'dark' ? colors.grey[100] : "white",
                    opacity: [0.9, 0.8, 0.7, 0.6, 0.5],
                    cursor: "pointer",
                    borderRadius: "0 0 20px 20px",
                    ...(!forChooseRelatedAds ? {
                        minHeight: {
                            xs: "550px",
                            lg: "400px"
                        },
                        maxHeight: {
                            xs: "550px",
                            lg: "400px"
                        }
                    } : {})
                } : {
                    ...(!forChooseRelatedAds ? {
                        minHeight: {
                            xs: "550px",
                            lg: "400px"
                        },
                        maxHeight: {
                            xs: "550px",
                            lg: "400px"
                        }
                    } : {})
                }}>

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => handleAdSelection(ad)}
                    onMouseEnter={() => { if (!!handleAdSelection) { setHover(true) } }}
                    onMouseLeave={() => { setHover(false) }}>
                    <Box ml="20px" pt="10px" pb="10px" >
                        <Typography variant='h3' color={colors.primary[900]}>{ad.brand_name}</Typography>
                        <Typography variant='h5' color={colors.primary[700]}>{ad.has_coupons === 0 ? "Alt Reklam" : ad.promotion_campaign === 0 ? "Alcago Kampanyası" : "Promosyon Kampanyası"}</Typography>
                        <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Son Geçerlilik: ${ad.last_usage_date.split("T")[0]}` : "Bu reklam kupon dağıtmamaktadır."}</Typography>
                        {ad.has_coupons !== 1 && <Typography variant='h6' color={colors.primary[700]}>{`Gösterim Limiti: ${ad.display_limit === 0 ? "Limitsiz" : ad.display_limit}`}</Typography>}
                        {!forChooseRelatedAds &&
                            <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? ad.promotion_campaign ? `Promosyon: ${ad.coupon_title}` : `Kupon İndirim Yüzdesi: ${ad.discount_percentage}` : <br />}</Typography>
                        }
                        {!forChooseRelatedAds &&
                            <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Sağlanan kupon sayısı: ${ad.starting_coupon_count}` : <br />}</Typography>
                        }
                        {!forChooseRelatedAds &&
                            <Typography variant='h6' color={colors.primary[700]}>{ad.has_coupons === 1 ? `Kalan kupon sayısı: ${ad.remaining_coupon_count}` : <br />}</Typography>
                        }
                    </Box>
                    <Box>
                        <Box height="15%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            p="5px 10px 5px 10px"
                            mr="10px"
                            mb="20px"
                            zIndex="10"
                            sx={{ background: colors.greenAccent[700], borderRadius: "20px" }}>
                            <RemoveRedEyeOutlinedIcon sx={{ color: colors.grey[100] }} />
                            <Typography variant='h6' color={colors.grey[100]} ml="5px">{ad.watch_count}</Typography>
                        </Box>
                    </Box>
                </Box>

                {!forChooseRelatedAds &&
                    <Box mx={"10px"}>
                        <Box>
                            <Checkbox
                                checked={featured}
                                disabled={ad.has_coupons === 0}
                                size="medium"
                                sx={{
                                    '&.Mui-checked': {
                                        color: (theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                    },
                                    '&.MuiCheckbox-root': {
                                        color: (ad.has_coupons === 0 ? colors.grey[300] : theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                    }
                                }}
                                onClick={(event) => {
                                    handleFeatured({ is_featured: !featured });
                                }}
                            />
                            <Typography color={ad.has_coupons === 0 ? colors.grey[300] : colors.primary[700]} display="inline">Öne Çıkarılmış (Ana Sayfa)</Typography>
                        </Box>
                        <Box>
                            <Checkbox
                                checked={featuredWalletPage}
                                disabled={ad.has_coupons === 0 || ad.promotion_campaign === 1}
                                size="medium"
                                ml="20px"
                                sx={{
                                    '&.Mui-checked': {
                                        color: (theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                    },
                                    '&.MuiCheckbox-root': {
                                        color: (ad.has_coupons === 0 || ad.promotion_campaign === 1 ? colors.grey[300] : theme.palette.mode === "dark" ? colors.greenAccent[700] : colors.greenAccent[500])
                                    }
                                }}
                                onClick={(event) => {
                                    handleFeatured({ is_featured_wallet_page: !featuredWalletPage });
                                }}
                            />
                            <Typography color={ad.has_coupons === 0 || ad.promotion_campaign === 1 ? colors.grey[300] : colors.primary[700]} display="inline">Öne Çıkarılmış (Cüzdan Sayfası)</Typography>
                        </Box>
                    </Box>
                }
                {!forChooseRelatedAds &&
                    <Box mt="10px" display="flex" justifyContent="center" gap="10px" rowGap="5px" flexWrap={"wrap"} px="5px">
                        {ad.has_coupons === 1 &&
                            <Button variant='outlined' color='info' onClick={() => {
                                handleRelatedAdsAddition(ad);
                            }}
                                sx={{
                                    height: "35px",
                                    borderColor: colors.blueAccent[600],
                                    color: colors.blueAccent[600]
                                }}>
                                Alt Reklam Ekle
                            </Button>
                        }
                        {
                            (ad.has_coupons === 0 && !handleDisplayLimitChange) &&
                            <Box height={"35px"} width={"200px"} sx={{
                                display: { xl: "none", xxl: "none" }
                            }} />
                        }
                        {
                            (ad.has_coupons === 0 && !!handleDisplayLimitChange) &&
                            <Button variant='outlined' color='info' onClick={() => {
                                handleDisplayLimitChange(ad);
                            }}
                                sx={{
                                    height: "35px",
                                    borderColor: colors.blueAccent[600],
                                    color: colors.blueAccent[600]
                                }}>
                                Gösterim Limiti Ayarla
                            </Button>
                        }
                        <Button variant='outlined' color='warning' onClick={() => navigate(`/admin-panel/coupon-details/${ad.id}`)}
                            sx={{
                                height: "35px",
                                borderColor: colors.primary[700],
                                color: colors.primary[700]
                            }}>
                            Reklam Detayını Gör
                        </Button>
                    </Box>
                }
            </Box>

        </Box>
    )
}

export default AdBoxForAdmin