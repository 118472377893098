import React from 'react'
import { Box, Button, Dialog, DialogContent, InputLabel, TextField, Typography, useTheme } from '@mui/material';
import * as yup from "yup";
import { Formik, useField, useFormikContext } from "formik";
import { tokens } from '../theme';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../index.css';

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
                setFieldTouched(field.name);
            }}
            className={props.mode}
        />
    );
};


function IndependentCouponDialog({ open, handleClose, handleSubmit, independentCoupon }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);

    var initialValues = {
        coupon_title: independentCoupon?.coupon_title ? independentCoupon.coupon_title : "",
        coupon_description: independentCoupon?.coupon_description ? independentCoupon.coupon_description : "",
        coupon_code: independentCoupon?.coupon_code ? independentCoupon.coupon_code : "",
        website_url: independentCoupon?.website_url ? independentCoupon.website_url : "",
        thumbnail: null,
        thumbnail1: null,
        thumbnail2: null,
        thumbnail3: null,
        thumbnail4: null,
        thumbnail5: null,
        thumbnail6: null,
        thumbnail_image_link: independentCoupon?.thumbnail_image_link ? independentCoupon.thumbnail_image_link : "",
        thumbnail_image_1_link: independentCoupon?.thumbnail_image_1_link ? independentCoupon.thumbnail_image_1_link : "",
        thumbnail_image_2_link: independentCoupon?.thumbnail_image_2_link ? independentCoupon.thumbnail_image_2_link : "",
        thumbnail_image_3_link: independentCoupon?.thumbnail_image_3_link ? independentCoupon.thumbnail_image_3_link : "",
        thumbnail_image_4_link: independentCoupon?.thumbnail_image_4_link ? independentCoupon.thumbnail_image_4_link : "",
        thumbnail_image_5_link: independentCoupon?.thumbnail_image_5_link ? independentCoupon.thumbnail_image_5_link : "",
        thumbnail_image_6_link: independentCoupon?.thumbnail_image_6_link ? independentCoupon.thumbnail_image_6_link : "",
        last_usage_date: independentCoupon?.last_usage_date ? new Date(independentCoupon.last_usage_date) : null
    }

    var shapeObject = {
        coupon_title: yup.string().required("zorunlu"),
        coupon_description: yup.string().required("zorunlu"),
        coupon_code: yup.string().nullable(),
        website_url: yup.string().url("URL formatında olmalıdır").nullable().when("coupon_code", {
            is: (coupon_code) => !(coupon_code),
            then: yup.string().url("URL formatında olmalıdır").required("Kupon kodu veya kampanya websitesi girilmelidir")
        }),
        thumbnail: yup.mixed().nullable().when('thumbnail_image_link', {
            is: (thumbnail_image_link) => !thumbnail_image_link || thumbnail_image_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail1: yup.mixed().nullable().when('thumbnail_image_1_link', {
            is: (thumbnail_image_1_link) => !thumbnail_image_1_link || thumbnail_image_1_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail2: yup.mixed().nullable().when('thumbnail_image_2_link', {
            is: (thumbnail_image_2_link) => !thumbnail_image_2_link || thumbnail_image_2_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail3: yup.mixed().nullable().when('thumbnail_image_3_link', {
            is: (thumbnail_image_3_link) => !thumbnail_image_3_link || thumbnail_image_3_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail4: yup.mixed().nullable().when('thumbnail_image_4_link', {
            is: (thumbnail_image_4_link) => !thumbnail_image_4_link || thumbnail_image_4_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail5: yup.mixed().nullable().when('thumbnail_image_5_link', {
            is: (thumbnail_image_5_link) => !thumbnail_image_5_link || thumbnail_image_5_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail6: yup.mixed().nullable().when('thumbnail_image_6_link', {
            is: (thumbnail_image_6_link) => !thumbnail_image_6_link || thumbnail_image_6_link === "",
            then: () => yup.mixed().required("zorunlu")
        }),
        thumbnail_image_link: yup.string(),
        thumbnail_image_1_link: yup.string(),
        thumbnail_image_2_link: yup.string(),
        thumbnail_image_3_link: yup.string(),
        thumbnail_image_4_link: yup.string(),
        thumbnail_image_5_link: yup.string(),
        thumbnail_image_6_link: yup.string(),
        last_usage_date: yup.date().nullable()
    }

    const schema = yup.object().shape(shapeObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={open}
            fullWidth
            maxWidth="md"
            onClose={handleClose}
        >
            <Box m="20px" mb="0" textAlign="center">
                <Typography variant='h3'>
                    {independentCoupon ? independentCoupon.coupon_title : "Yeni Kupon Ekle"}
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={schema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" my="25px">
                                    <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                        <Typography variant="h6">Görsel (1200 x 620)</Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                            {(values.thumbnail || (values.thumbnail_image_link && values.thumbnail_image_link !== "")) && <img alt='thumbnail' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail ? URL.createObjectURL(values.thumbnail) : values.thumbnail_image_link} />}
                                            <input id="thumbnail" name="thumbnail" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>
                                    <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                        <Typography variant="h6">Görsel (560 x 560)</Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                            {(values.thumbnail1 || (values.thumbnail_image_1_link && values.thumbnail_image_1_link !== "")) && <img alt='thumbnail1' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail1 ? URL.createObjectURL(values.thumbnail1) : values.thumbnail_image_1_link} />}
                                            <input id="thumbnail1" name="thumbnail1" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail1", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>
                                    <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                        <Typography variant="h6">Görsel (1590 x 725)</Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                            {(values.thumbnail2 || (values.thumbnail_image_2_link && values.thumbnail_image_2_link !== "")) && <img alt='thumbnail2' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail2 ? URL.createObjectURL(values.thumbnail2) : values.thumbnail_image_2_link} />}
                                            <input id="thumbnail2" name="thumbnail2" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail2", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>
                                    <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                        <Typography variant="h6">Görsel (390 x 310)</Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                            {(values.thumbnail3 || (values.thumbnail_image_3_link && values.thumbnail_image_3_link !== "")) && <img alt='thumbnail3' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail3 ? URL.createObjectURL(values.thumbnail3) : values.thumbnail_image_3_link} />}
                                            <input id="thumbnail3" name="thumbnail3" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail3", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" sx={{ gridColumn: "span 4" }}>
                                        <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                            <Typography variant="h6">Görsel (960 x 920)</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                                {(values.thumbnail4 || (values.thumbnail_image_4_link && values.thumbnail_image_4_link !== "")) && <img alt='thumbnail4' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail4 ? URL.createObjectURL(values.thumbnail4) : values.thumbnail_image_4_link} />}
                                                <input id="thumbnail4" name="thumbnail4" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail4", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                        <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                            <Typography variant="h6">Görsel (840 x 640)</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                                {(values.thumbnail5 || (values.thumbnail_image_5_link && values.thumbnail_image_5_link !== "")) && <img alt='thumbnail5' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail5 ? URL.createObjectURL(values.thumbnail5) : values.thumbnail_image_5_link} />}
                                                <input id="thumbnail5" name="thumbnail5" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail5", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                        <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                            <Typography variant="h6">Görsel (890 x 550)</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[700], height: "150px", width: "150px" }}>
                                                {(values.thumbnail6 || (values.thumbnail_image_6_link && values.thumbnail_image_6_link !== "")) && <img alt='thumbnail6' style={{ maxWidth: "140px", maxHeight: "140px", minWidth: "140px", minHeight: "140px", objectFit: "contain" }} src={values.thumbnail6 ? URL.createObjectURL(values.thumbnail6) : values.thumbnail_image_6_link} />}
                                                <input id="thumbnail6" name="thumbnail6" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail6", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kupon Başlığı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.coupon_title}
                                        name="coupon_title"
                                        error={!!touched.coupon_title && !!errors.coupon_title}
                                        helperText={touched.coupon_title && errors.coupon_title}
                                        sx={{ gridColumn: "span 4", mt: "10px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kupon Açıklaması"
                                        multiline
                                        rows={6}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.coupon_description}
                                        name="coupon_description"
                                        error={!!touched.coupon_description && !!errors.coupon_description}
                                        helperText={touched.coupon_description && errors.coupon_description}
                                        sx={{ gridColumn: "span 4" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kupon Kodu"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.coupon_code}
                                        name="coupon_code"
                                        error={!!touched.coupon_code && !!errors.coupon_code}
                                        helperText={touched.coupon_code && errors.coupon_code}
                                        sx={{ gridColumn: "span 1", mt: "18px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kampanya Websitesi"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.website_url}
                                        name="website_url"
                                        error={!!touched.website_url && !!errors.website_url}
                                        helperText={touched.website_url && errors.website_url}
                                        sx={{ gridColumn: "span 2", mt: "18px" }}
                                    />

                                    <Box gridColumn="span 1">
                                        <InputLabel size='normal' error={!!touched.last_usage_date && !!errors.last_usage_date} htmlFor="last_usage_date">Kupon son kullanma tarihi:</InputLabel>
                                        <DatePickerField name="last_usage_date" mode={theme.palette.mode} />
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default IndependentCouponDialog