import { Button, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import StatCard from './StatCard';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';

function RefCodeBoxWithOwner({ refCode, type, onToggleWebRegistirationPermissionButtonClick }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [earning, setEarning] = useState(0);
    const [bestBrand, setBestBrand] = useState({
        brand_name: "",
        total_price: null,
        logo_link: "",
    });

    const getEarningStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&field=${type}&refId=${refCode.id}` + (type === "influencer_brand" ? `&id=${user.id}` : ""))
            .catch((err) => {
                console.log("err: " + err);
                setEarning(0);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setEarning(response.data.statistics.total);
                }
            });
    }

    const getBestBrandStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardBestBrandStatistics?token=${token}&field=${type}&refId=${refCode.id}` + (type === "influencer_brand" ? `&id=${user.id}` : ""))
            .catch((err) => {
                console.log("err: " + err);
                setBestBrand({
                    brand_name: "",
                    total_price: null,
                    logo_link: "",
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setBestBrand(response.data.statistics.total);
                }
            });
    }

    useEffect(() => {
        getBestBrandStatistics();
        getEarningStatistics();
    }, [])


    return (
        <Box mb="20px" p="20px" minWidth="250px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="top" mb="15px">
                <Box>
                    <Box display="flex" alignItems="center" gap="10px">
                        <img
                            alt="profile"
                            src={refCode.owner_profile_photo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Typography variant='h4' noWrap>{refCode.owner_name}</Typography>
                        <Typography variant='h4' noWrap>-</Typography>
                        <Typography variant='h4'>({refCode.ref_code})</Typography>
                    </Box>
                    <Button
                        variant='contained'
                        color={refCode.ad_id ? "error" : "success"}
                        onClick={() => onToggleWebRegistirationPermissionButtonClick(refCode, type)}
                        sx={{ mt: "10px" }}
                    >
                        <Typography variant='h6' textTransform='none'>{refCode.ad_id ? "Web Kaydını Kapat" : "Web Kaydına İzin Ver"}</Typography>
                    </Button>
                    {refCode.ad_id &&
                        <Box>
                            <Typography variant='h6' mt="10px" noWrap>
                                <b>{refCode.direct_gift === 1 ? "Verilecek Kupon:" : "İzlenecek Reklam:"}</b> {`${refCode.coupon_title}`}
                            </Typography>
                            <Typography variant='h6' mt="10px" noWrap>
                                <b>Link:</b> <Box
                                    component={"a"}
                                    href={`${process.env.REACT_APP_SIGN_UP_URL}${refCode.ref_code}`}
                                    rel='noreferrer'
                                    target='_blank'
                                    sx={{ color: colors.grey[100] }}
                                >
                                    {process.env.REACT_APP_SIGN_UP_URL + refCode.ref_code}
                                </Box>
                            </Typography>
                        </Box>
                    }
                </Box>
                <Box display="flex" flexDirection="column" alignItems="end" gap="10px">
                    <Typography variant='h6'>{new Date(refCode.created_date).toLocaleDateString()}</Typography>

                </Box>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box display="flex" mt="20px">
                <StatCard icon={<PersonIcon sx={{ scale: "1.5" }} />} count={refCode.userCount + ""} content="Kullanıcı" />
                <StatCard icon={<CardMembershipOutlinedIcon sx={{ scale: "1.5" }} />} count={refCode.couponCount + ""} content="Alınan Kupon" />
                <StatCard icon={<Box textAlign="center">{bestBrand && bestBrand.logo_link !== "" && <img
                    alt="profile-user"
                    src={bestBrand.logo_link}
                    width="40px"
                    height="40px"
                    style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />}<Typography>{bestBrand && bestBrand.brand_name !== "" ? bestBrand.brand_name : "-"}</Typography></Box>} count={(bestBrand && bestBrand.total_price ? bestBrand.total_price.toFixed(2) : "0") + " ₺"} content="En Çok Kazandıran" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5" }} />} count={earning.toFixed(2) + " ₺"} content="Toplam Kazanç" />
            </Box>
        </Box >
    )
}

export default RefCodeBoxWithOwner