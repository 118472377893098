import { PlayArrowRounded } from '@mui/icons-material'
import { Box } from '@mui/material'
import React, { useState } from 'react'

function VideoPlayerWithThumbnail({ thumbnailLink, videoLink }) {
    const [isVideoStarted, setIsVideoStarted] = useState(false);
    return (
        <>
            {!isVideoStarted &&
                <Box position="relative" onClick={() => setIsVideoStarted(true)}>
                    {/** play icon */}
                    <Box display="flex" width="100%" height="100%" style={{ position: "absolute", zIndex: 1 }}>
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="98%" style={{ backgroundColor: "rgba(0,0,0,0.5)", borderRadius: "18px 18px 0px 0px" }}>
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ backgroundColor: "rgba(256,256,256,0.4)", borderRadius: "50px", padding: "5px" }}>
                                <PlayArrowRounded sx={{ color: "rgba(0,0,0,0.8)", fontSize: "50px" }} />
                            </Box>
                        </Box>
                    </Box>
                    {/** If there is no video, show thumbnail image */}
                    <Box component={"img"} src={thumbnailLink} width="100%" maxHeight="300px" controls style={{ borderRadius: "20px 20px 0px 0px", objectFit: "cover" }} />
                </Box>
            }
            {isVideoStarted &&
                <Box display="flex" justifyContent="end">
                    <video width="100%" autoPlay controls style={{ borderRadius: "20px 20px 0px 0px", objectFit: "contain", maxHeight: "300px" }}>
                        <source src={videoLink} type="video/mp4" />
                    </video>
                </Box>
            }
        </>
    )
}

export default VideoPlayerWithThumbnail