import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//TODO: getCouponsWithUserId request changed
function CouponBox({ coupon, userId }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const { token } = useContext(UserContext);
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = useState(false);

    const getOrderDetails = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getOrderDetails?token=${token}&orderId=${coupon.order_id}`)
            .catch((err) => {
                console.log("err: " + err);
                setOrderDetails([])
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 200) {
                    setOrderDetails(response.data.orderDetails);
                }
            });
    }

    useEffect(() => {
        if (coupon.status !== 0 && coupon.order_id) {
            getOrderDetails();
        }
    }, []);

    function convertTime(timeString) {
        const timeArr = timeString.split(':');
        const hours = parseInt(timeArr[0]);
        const minutes = parseInt(timeArr[1]);
        const seconds = parseInt(timeArr[2]);
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;

        return `${days > 0 ? days + ' gün, ' : ''}${remainingHours > 0 ? remainingHours + ' saat, ' : ''}${minutes > 0 ? minutes + ' dakika, ' : ''}${seconds > 0 ? seconds + ' saniye' : ''}`;
    }

    function formatDuration(seconds) {
        var days = Math.floor(seconds / (3600 * 24));
        var hours = Math.floor((seconds % (3600 * 24)) / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var secs = seconds % 60;

        var durationArr = [];
        if (days > 0) {
            durationArr.push(days + " gün");
        }
        if (hours > 0) {
            durationArr.push(hours + " saat");
        }
        if (minutes > 0) {
            durationArr.push(minutes + " dakika");
        }
        if (seconds > 0) {
            durationArr.push(secs + " saniye");
        }

        if (durationArr.length === 0) {
            return "0 saniye";
        } else {
            if (durationArr.length > 1) {
                return durationArr.join(", ");
            } else {
                return durationArr[0]
            }
        }
    }

    return (
        <Box m="10px" mb="20px" p="20px"
            sx={{
                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                borderRadius: "20px"
            }}
        >
            <Dialog
                open={orderDetailsDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOrderDetailsDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle variant='h3' mb="20px" >Sipariş Detayları</DialogTitle>
                <DialogContent>
                    <Box>
                        {orderDetails.map((detail) => <Typography key={"detail" + detail.id} variant="h6">{detail.quantity + "x " + detail.product_name + ": " + detail.selling_price + "₺"}</Typography>)}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOrderDetailsDialogOpen(false)}><Typography variant='h6' color="error">Kapat</Typography></Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" >
                    <Box>
                        <img
                            alt="brand-logo"
                            src={coupon.brand_logo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                    </Box>
                    <Box ml="10px">
                        <Box display="flex" gap="5px">
                            <Typography variant='h4'>{coupon.brand_name}</Typography>
                            <Box padding="1px 10px" sx={{ background: coupon.status === 0 ? colors.redAccent[600] : colors.greenAccent[600], borderRadius: "10px" }}>
                                <Typography variant='h6'>{coupon.status === 1 ? "Kullanıldı" : coupon.is_expired === 1 ? "Süresi Dolmuş" : "Kullanılmadı"}</Typography>
                            </Box>
                        </Box>
                        <Typography variant='h6' >{coupon.title}</Typography>
                    </Box>
                </Box>
                <Box display="flex">
                    {orderDetails.length > 0 &&
                        <Box>
                            <IconButton onClick={() => { setOrderDetailsDialogOpen(true) }}>
                                <ReceiptLongOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                            </IconButton>
                        </Box>
                    }
                    <Box>
                        <IconButton onClick={() => { navigate(`/admin-panel/coupon-details/${userId}/${coupon.ad_id}`); }}>
                            <InfoOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box>
                {coupon.seconds && <Typography display="inline-block" variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Kuponu Alma Süresi:</b> <br />{formatDuration(coupon.seconds)}</Typography>}
                {coupon.usage_time_diff && <Typography display="inline-block" variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Kullanım Süresi:</b> <br />{convertTime(coupon.usage_time_diff)}</Typography>}
                {coupon.total_price && <Typography display="inline-block" variant='h6' maxWidth="250px" mt="10px" ml="20px"><b>Sepet tutarı:</b> <br />{coupon.total_price + " ₺"}</Typography>}
            </Box>
        </Box >
    )
}

export default CouponBox