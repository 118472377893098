import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react'
import Header from '../../../../../components/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import StatCard from '../../../../../components/StatCard';
import { tokens } from '../../../../../theme';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import CommentBox from '../../../../../components/CommentBox';
import AdBox from '../../../../../components/AdBox';
import AlertDialogSlide from '../../../../../components/AlertDialogSlide';
import RegisterDialog from '../../../../loginPages/brandLogin/registerDialog';
import IndependentCouponBox from '../../../../../components/IndependentCouponBox';
import IndependentCouponDialog from '../../../../../components/IndependentCouponDialog';

function BrandDetails() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const brandId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];

    const [brand, setBrand] = useState(null);
    const [ads, setAds] = useState([]);


    const [comments, setComments] = useState([]);
    const [independentCoupons, setIndependentCoupons] = useState([]);


    const [earningStatistics, setEarningStatistics] = useState({
        today: 0,
        thisMonth: 0,
        thisYear: 0,
        total: 0
    });

    const [deleteIndependentCouponAlertDialogState, setDeleteIndependentCouponAlertDialogState] = useState({
        open: false,
        selectedCoupon: null
    });

    const [editIndependentCouponDialogState, setEditIndependentCouponDialogState] = useState({
        open: false,
        selectedCoupon: null
    });

    const [openAddCouponDialog, setOpenAddCouponDialog] = useState(false);

    const getEarningStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&id=${brandId}&field=brand`)
            .catch((err) => {
                console.log("err: " + err);
                setEarningStatistics({
                    today: 0,
                    thisMonth: 0,
                    thisYear: 0,
                    total: 0
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setEarningStatistics(response.data.statistics);
                }
            });
    }

    const approveComment = (commentId) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/approveComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments[ix].is_approved = 1;
                        var tempComments = JSON.stringify(comments);
                        setComments([]);
                        setComments(JSON.parse(tempComments));
                    }
                }
            });
    }
    const deleteComment = (commentId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments.splice(ix, 1);
                        var tempComments = JSON.stringify(comments);
                        setComments([]);
                        setComments(JSON.parse(tempComments));
                    }
                }
            });
    }


    const [featured, setFeatured] = useState(false);
    const [active, setActive] = useState(false);
    const [isInfluencer, setIsInfluencer] = useState(false);
    const handleFeatured = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/changeFeaturedValue?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }
    const handleActive = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/toggleIsActive?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }
    const handleInfluencerStatus = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/changeInfluencerStatus?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }
    useEffect(() => {
        setFeatured(brand ? brand.is_featured > 0 : false);
        setActive(brand ? brand.is_active > 0 : false);
        setIsInfluencer(brand ? brand.is_influencer > 0 : false);
    }, [brand]);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteBrand?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    navigate("/admin-panel/brands");
                }
            });
    }
    const clickNegativeAnswer = () => { }



    useEffect(() => {
        fetchBrandData()
        getEarningStatistics();
    }, []);

    const fetchBrandData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandDetailsWithId?token=${token}&brandId=${brandId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.brand && response.data.brand.brand) {
                        setBrand(response.data.brand.brand);
                        if (response.data.brand.ads) {
                            setAds(response.data.brand.ads);
                        }
                        if (response.data.brand.comments) {
                            setComments(response.data.brand.comments);
                        }
                        if (response.data.brand.independentCoupons) {
                            setIndependentCoupons(response.data.brand.independentCoupons);
                        }
                    }
                }
            });
    }

    const [openBrandDialog, setOpenBrandDialog] = useState(false);
    const handleBrandDialogClose = () => {
        fetchBrandData();
        setOpenBrandDialog(false);
    }

    const openBrandUsers = (brand) => {
        navigate(`/admin-panel/users/br/${brand.id}/${brand.name}`);
    }


    const handleDeleteIndependentCouponAlertDialogClose = () => {
        setDeleteIndependentCouponAlertDialogState({
            open: false,
            selectedCoupon: null
        });
    }

    const deleteIndependentCoupon = (independentCouponId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteIndependentCoupon?token=${token}&independentCouponId=${independentCouponId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = independentCoupons.findIndex(({ id }) => id === independentCouponId);
                    if (ix >= 0) {
                        independentCoupons.splice(ix, 1);
                        var tempIndependentCoupons = JSON.stringify(independentCoupons);
                        setIndependentCoupons([]);
                        setIndependentCoupons(JSON.parse(tempIndependentCoupons));
                    }
                    handleDeleteIndependentCouponAlertDialogClose();
                }
            });
    }

    const createIndependentCoupon = async (values) => {
        if (values.thumbnail || values.thumbnail1 || values.thumbnail2 || values.thumbnail3 || values.thumbnail4 || values.thumbnail5 || values.thumbnail6) {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadIndependentCouponThumbnail?token=${token}`, {
                ...(values.thumbnail ? { thumbnail: values.thumbnail } : {}),
                ...(values.thumbnail1 ? { thumbnail1: values.thumbnail1 } : {}),
                ...(values.thumbnail2 ? { thumbnail2: values.thumbnail2 } : {}),
                ...(values.thumbnail3 ? { thumbnail3: values.thumbnail3 } : {}),
                ...(values.thumbnail4 ? { thumbnail4: values.thumbnail4 } : {}),
                ...(values.thumbnail5 ? { thumbnail5: values.thumbnail5 } : {}),
                ...(values.thumbnail6 ? { thumbnail6: values.thumbnail6 } : {})
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (values.thumbnail) {
                            delete values.thumbnail;
                            delete values.thumbnail_image_link;
                            values.thumbnail_image_path = response.data.thumbnailPath;
                        }
                        if (values.thumbnail1) {
                            delete values.thumbnail1;
                            delete values.thumbnail_image_1_path;
                            values.thumbnail_image_1_path = response.data.thumbnailPath1;
                        }
                        if (values.thumbnail2) {
                            delete values.thumbnail2;
                            delete values.thumbnail_image_2_path;
                            values.thumbnail_image_2_path = response.data.thumbnailPath2;
                        }
                        if (values.thumbnail3) {
                            delete values.thumbnail3;
                            delete values.thumbnail_image_3_path;
                            values.thumbnail_image_3_path = response.data.thumbnailPath3;
                        }
                        if (values.thumbnail4) {
                            delete values.thumbnail4;
                            delete values.thumbnail_image_4_path;
                            values.thumbnail_image_4_path = response.data.thumbnailPath4;
                        }
                        if (values.thumbnail5) {
                            delete values.thumbnail5;
                            delete values.thumbnail_image_5_path;
                            values.thumbnail_image_5_path = response.data.thumbnailPath5;
                        }
                        if (values.thumbnail6) {
                            delete values.thumbnail6;
                            delete values.thumbnail_image_6_path;
                            values.thumbnail_image_6_path = response.data.thumbnailPath6;
                        }
                    }
                });
        }
        values.brand_id = brand.id;
        delete values.thumbnail;
        delete values.thumbnail_image_link;
        delete values.thumbnail1;
        delete values.thumbnail_image_1_link;
        delete values.thumbnail2;
        delete values.thumbnail_image_2_link;
        delete values.thumbnail3;
        delete values.thumbnail_image_3_link;
        delete values.thumbnail4;
        delete values.thumbnail_image_4_link;
        delete values.thumbnail5;
        delete values.thumbnail_image_5_link;
        delete values.thumbnail6;
        delete values.thumbnail_image_6_link;
        axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/createIndependentCoupon?token=${token}`, values)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchBrandData();
                    setOpenAddCouponDialog(false);
                }
            });
    }

    const editIndependentCoupon = async (independentCouponId, values) => {
        if (values.thumbnail || values.thumbnail1 || values.thumbnail2 || values.thumbnail3 || values.thumbnail4 || values.thumbnail5 || values.thumbnail6) {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadIndependentCouponThumbnail?token=${token}`, {
                ...(values.thumbnail ? { thumbnail: values.thumbnail } : {}),
                ...(values.thumbnail1 ? { thumbnail1: values.thumbnail1 } : {}),
                ...(values.thumbnail2 ? { thumbnail2: values.thumbnail2 } : {}),
                ...(values.thumbnail3 ? { thumbnail3: values.thumbnail3 } : {}),
                ...(values.thumbnail4 ? { thumbnail4: values.thumbnail4 } : {}),
                ...(values.thumbnail5 ? { thumbnail5: values.thumbnail5 } : {}),
                ...(values.thumbnail6 ? { thumbnail6: values.thumbnail6 } : {})
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (values.thumbnail) {
                            delete values.thumbnail;
                            delete values.thumbnail_image_link;
                            values.thumbnail_image_path = response.data.thumbnailPath;
                        }
                        if (values.thumbnail1) {
                            delete values.thumbnail1;
                            delete values.thumbnail_image_1_path;
                            values.thumbnail_image_1_path = response.data.thumbnailPath1;
                        }
                        if (values.thumbnail2) {
                            delete values.thumbnail2;
                            delete values.thumbnail_image_2_path;
                            values.thumbnail_image_2_path = response.data.thumbnailPath2;
                        }
                        if (values.thumbnail3) {
                            delete values.thumbnail3;
                            delete values.thumbnail_image_3_path;
                            values.thumbnail_image_3_path = response.data.thumbnailPath3;
                        }
                        if (values.thumbnail4) {
                            delete values.thumbnail4;
                            delete values.thumbnail_image_4_path;
                            values.thumbnail_image_4_path = response.data.thumbnailPath4;
                        }
                        if (values.thumbnail5) {
                            delete values.thumbnail5;
                            delete values.thumbnail_image_5_path;
                            values.thumbnail_image_5_path = response.data.thumbnailPath5;
                        }
                        if (values.thumbnail6) {
                            delete values.thumbnail6;
                            delete values.thumbnail_image_6_path;
                            values.thumbnail_image_6_path = response.data.thumbnailPath6;
                        }
                    }
                });
        }

        delete values.thumbnail;
        delete values.thumbnail_image_link;
        delete values.thumbnail1;
        delete values.thumbnail_image_1_link;
        delete values.thumbnail2;
        delete values.thumbnail_image_2_link;
        delete values.thumbnail3;
        delete values.thumbnail_image_3_link;
        delete values.thumbnail4;
        delete values.thumbnail_image_4_link;
        delete values.thumbnail5;
        delete values.thumbnail_image_5_link;
        delete values.thumbnail6;
        delete values.thumbnail_image_6_link;

        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateIndependentCoupon?token=${token}&independentCouponId=${independentCouponId}`, {
            independentCoupon: values
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchBrandData();
                    setEditIndependentCouponDialogState({
                        open: false,
                        selectedCoupon: null
                    });
                }
            });
    }

    return (
        <Box mt="20px">
            {!brand &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {brand &&
                <Box>
                    <AlertDialogSlide
                        open={openAlertDialog} handleClose={handleAlertDialogClose}
                        clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                        positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                        title={"Bu markayı silmek istediğinize emin misiniz?"}
                        description={"Bu marka ile ilişkili tüm veriler silinecektir. (kullanıcı kuponları, izleme istatistikleri, reklamlar, reklam grupları vb.)"}
                    />
                    {
                        (deleteIndependentCouponAlertDialogState.open && deleteIndependentCouponAlertDialogState.selectedCoupon) &&
                        <AlertDialogSlide
                            open={deleteIndependentCouponAlertDialogState.open} handleClose={handleDeleteIndependentCouponAlertDialogClose}
                            clickPositiveAnswer={() => {
                                deleteIndependentCoupon(deleteIndependentCouponAlertDialogState.selectedCoupon.id);
                            }} clickNegativeAnswer={() => { }}
                            positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                            title={"Bu kuponu silmek istediğinize emin misiniz?"}
                            description={"Bu kupon ile ilişkili tüm veriler silinecektir. (İstatistikler vb.)"}
                        />
                    }
                    {
                        ((editIndependentCouponDialogState.open && editIndependentCouponDialogState.selectedCoupon) || openAddCouponDialog) &&
                        <IndependentCouponDialog
                            open={editIndependentCouponDialogState.open || openAddCouponDialog}
                            handleClose={() => {
                                setEditIndependentCouponDialogState({
                                    open: false,
                                    selectedCoupon: null
                                });
                                setOpenAddCouponDialog(false);
                            }}
                            independentCoupon={editIndependentCouponDialogState.selectedCoupon}
                            handleSubmit={(values) => {
                                if (openAddCouponDialog) {
                                    createIndependentCoupon(values);
                                } else if (editIndependentCouponDialogState.selectedCoupon) {
                                    editIndependentCoupon(editIndependentCouponDialogState.selectedCoupon.id, values);
                                }
                            }}
                        />
                    }
                    {brand && brand.id && <RegisterDialog open={openBrandDialog} handleClose={handleBrandDialogClose} type="edit" brand={brand} />}
                    <Box display="flex">
                        <Box display="flex" width="70%">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    navigate("/admin-panel/brands");
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" alignItems="center">
                                <Header title={brandId + " - " + brand.name} subtitle={brand.description} />
                            </Box>
                        </Box>

                        <Box display="flex" gap="10%" ml="20px" alignItems="center" width="30%">
                            <Button variant="contained" style={{ background: colors.blueAccent[500] }} onClick={() => setOpenBrandDialog(true)}>
                                Düzenle
                            </Button>
                            <Button variant="contained" style={{ background: colors.redAccent[500] }} onClick={() => setOpenAlertDialog(true)}>
                                Sil
                            </Button>
                        </Box>

                    </Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px">
                        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={brand.fav_count.toString()} content="Takipçi" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<RemoveRedEyeOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={brand.view_count.toString()} content="Görüntülenme" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<FeaturedVideoOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={ads.length.toString()} content="Reklam" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={
                            <SlideshowOutlinedIcon
                                sx={{ scale: "1.5", ml: "10px" }} />}
                            count={ads.length > 0 ? ads.map((ad) => ad.watch_count || 0).reduce((a, b) => a + b) : 0}
                            content="Reklam İzlenmesi"
                            contentVariant="h6"
                            sx={{ gridColumn: "span 1" }} />
                        <Box width="100%" m="0 30px" p="5px 5px 5px 10px" sx={{ background: colors.primary[400], gridColumn: "span 1", borderRadius: "20px" }}>
                            <Box display="inline-block" mr="20px">
                                <Checkbox
                                    checked={active}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                        }
                                    }}
                                    onClick={(event) => {
                                        setActive(!active);
                                        handleActive();
                                    }}
                                />
                                <Typography variant='h7'>Aktif</Typography>
                            </Box>
                            <Box display="inline-block">
                                <Checkbox
                                    checked={featured}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                        }
                                    }}
                                    onClick={(event) => {
                                        setFeatured(!featured);
                                        handleFeatured();
                                    }}
                                />
                                <Typography variant='h7'>Öne Çıkarılmış</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    checked={isInfluencer}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                        }
                                    }}
                                    onClick={(event) => {
                                        setIsInfluencer(!isInfluencer);
                                        handleInfluencerStatus();
                                    }}
                                />
                                <Typography variant='h7'>Catcher Inviter</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px" mt="10px" gridColumn="span 6">
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.today.toFixed(2) + " ₺"} content="Bugün" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisMonth.toFixed(2) + " ₺"} content="Bu ay" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisYear.toFixed(2) + " ₺"} content="Bu yıl" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.total.toFixed(2) + " ₺"} content="Toplam" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <Button variant="contained" color="secondary" sx={{ m: "20px 40px 20px 80px", gridColumn: "span 1" }} onClick={() => openBrandUsers(brand)}>
                            Catcherları Gör
                        </Button>
                    </Box>
                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={ads.length < 1}>
                            <AccordionSummary id='ad-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Reklamlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    <Box m="10px 0 0 0">
                                        {ads.map((ad) =>
                                        (
                                            <AdBox
                                                key={ad.id + "ads"}
                                                id={ad.id}
                                                coupon_title={ad.coupon_title}
                                                has_coupons={ad.has_coupons}
                                                promotion_campaign={ad.promotion_campaign}
                                                discount_percentage={ad.discount_percentage}
                                                watch_count={ad.watch_count}
                                                last_usage_date={ad.last_usage_date}
                                                video_link={ad.video_link}
                                                thumbnail_link={ad.thumbnail_image_link}
                                                is_active={ad.is_active}
                                                width="30%" />
                                        )
                                        )}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={typeof comments === "string" || comments.length < 1}>
                            <AccordionSummary id='comment-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yorumlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    {typeof comments !== "string" && comments.map((comment) =>
                                    (
                                        <Box key={comment.id + "comments"} >
                                            <CommentBox comment={comment} isEditable={true} approveComment={approveComment} deleteComment={deleteComment} />
                                        </Box>
                                    )
                                    )}

                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary id='comment-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Diğer Kuponlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    <Button variant="contained" color="secondary" sx={{ ml: "10px" }} onClick={() => {
                                        setOpenAddCouponDialog(true);
                                    }}>
                                        Yeni Kupon Ekle
                                    </Button>
                                    <Box m="10px 0 0 0">
                                        {independentCoupons.map((coupon) =>
                                        (
                                            <IndependentCouponBox
                                                key={coupon.id + "independentCoupons"}
                                                independentCoupon={coupon}
                                                onEditClick={(coupon) => {
                                                    setEditIndependentCouponDialogState({
                                                        open: true,
                                                        selectedCoupon: coupon
                                                    });
                                                }}
                                                onDeleteClick={(coupon) => {
                                                    setDeleteIndependentCouponAlertDialogState({
                                                        open: true,
                                                        selectedCoupon: coupon
                                                    });
                                                }}
                                            />
                                        )
                                        )}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default BrandDetails;