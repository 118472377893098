import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Doughnut, Line, Bubble } from 'react-chartjs-2'
// import { useTheme } from '@emotion/react';
// import { tokens } from '../../theme';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as ChartGeo from 'chartjs-chart-geo';
import turTopo from './tur.topo.json';
import cities from './cities.json';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"
import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useParams } from 'react-router';
import axios from 'axios';
Chart.register(
    ChartDataLabels,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function ReportsPage() {

    // const theme = useTheme();
    // const colors = tokens("light");

    //https://github.com/markmarkoh/datamaps/blob/master/src/js/data/tur.topo.json

    const [report, setReport] = useState(null);
    const { reportUID } = useParams();

    const isXL = useMediaQuery({ query: '(min-width: 1440px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1024px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    const [geoData, setGeoData] = useState([]);
    const [toolTipInfo, setToolTipInfo] = useState(null);
    const [dateFilter, setDateFilter] = useState("all");

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/report/getReport?reportUID=${reportUID}`)
            .catch((err) => {
                console.log("err: " + err);
                setReport(null);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    var groupedActions = [];
                    var groupedWatchs = [];
                    if (response.data.report.actions.length > 0) {
                        for (let i = 0; i < response.data.report.actions.length; i++) {
                            const action = response.data.report.actions[i];
                            const tempDate = new Date(action.statistic_date);
                            action.statistic_date = tempDate.getFullYear() + "-" + (tempDate.getMonth() < 9 ? "0" : "") + (tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" : "") + tempDate.getDate()
                            const groupedAction = groupedActions.findIndex(groupedAction => groupedAction.statistic_date === action.statistic_date);
                            if (groupedAction !== -1) {
                                groupedActions[groupedAction].count += 1;
                            } else {
                                groupedActions.push({ ...action, count: 1 });
                            }
                        }
                        for (let i = 0; i < response.data.report.watchs.length; i++) {
                            const watch = response.data.report.watchs[i];
                            const tempDate = new Date(watch.statistic_date);
                            watch.statistic_date = tempDate.getFullYear() + "-" + (tempDate.getMonth() < 9 ? "0" : "") + (tempDate.getMonth() + 1) + "-" + (tempDate.getDate() < 10 ? "0" : "") + tempDate.getDate()
                            const groupedWatch = groupedWatchs.findIndex(groupedWatch => groupedWatch.statistic_date === watch.statistic_date);
                            if (groupedWatch !== -1) {
                                groupedWatchs[groupedWatch].count += 1;
                            } else {
                                groupedWatchs.push({ ...watch, count: 1 });
                            }
                        }
                        //add grouped actions {count: 0} for missing dates (YYYY-MM-DD)
                        let groupedActionsDates = groupedActions.map(action => action.statistic_date);
                        const groupedWatchsDates = groupedWatchs.map(watch => watch.statistic_date);
                        const missingDates = groupedWatchsDates.filter(date => !groupedActionsDates.includes(date));
                        missingDates.forEach(date => {
                            groupedActions.push({ statistic_date: date, count: 0 });
                        });
                        groupedActions.sort((a, b) => a.statistic_date.localeCompare(b.statistic_date));

                        //insert fully missing dates
                        const firstDate = new Date(groupedActions[0].statistic_date);
                        const lastDate = new Date(groupedActions[groupedActions.length - 1].statistic_date);
                        for (let i = new Date(firstDate); i <= lastDate; i.setDate(i.getDate() + 1)) {
                            const date = i.getFullYear() + "-" + (i.getMonth() < 9 ? "0" : "") + (i.getMonth() + 1) + "-" + (i.getDate() < 10 ? "0" : "") + i.getDate();
                            if (!groupedActionsDates.includes(date)) {
                                groupedActions.push({ statistic_date: date, count: 0 });
                            }
                        }
                        groupedActions.sort((a, b) => a.statistic_date.localeCompare(b.statistic_date));
                        groupedActionsDates = groupedActions.map(action => action.statistic_date);

                        const missingDatesForActions = groupedActionsDates.filter(date => !groupedWatchsDates.includes(date));
                        missingDatesForActions.forEach(date => {
                            groupedWatchs.push({ statistic_date: date, count: 0 });
                        });
                        groupedWatchs.sort((a, b) => a.statistic_date.localeCompare(b.statistic_date));

                    }
                    setReport({ ...(response.data.report), groupedActions: groupedActions, groupedWatchs: groupedWatchs });
                    const fetchedCities = ChartGeo.topojson.feature(turTopo, turTopo.objects.tur).features;
                    // fetchedCities.sort((a, b) => a.properties.name.localeCompare(b.properties.name));
                    console.log({ ...(response.data.report), groupedActions: groupedActions, groupedWatchs: groupedWatchs });
                    setGeoData(
                        [
                            ...fetchedCities.map(city => [city.properties.name, response.data.report.watchs && response.data.report.watchs.length > 0 && city.properties.name ? response.data.report.watchs.filter(log => log.city.toLowerCase() === city.properties.name.toLowerCase()).length + ` (%${100 * (response.data.report.watchs.filter(log => log.city.toLowerCase() === city.properties.name.toLowerCase()).length) / (response.data.report.watchs.length)})` : 0 + " (%0)"])
                        ]
                    )
                }
            });
    }, []);

    if (report) {
        return (
            <Box backgroundColor="#F5F5F5" sx={{ pt: "60px", pb: "120px" }}>
                {toolTipInfo &&
                    <Box position="fixed" top={toolTipInfo.y} left={toolTipInfo.x} sx={{ backgroundColor: "#363B64", color: "white", p: "10px", borderRadius: "10px", zIndex: 1000 }}>
                        <Typography>{toolTipInfo.name}</Typography>
                        <Typography sx={(report.is_blurred === 1 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {})}>{toolTipInfo.value}</Typography>
                    </Box>
                }
                <Box px="10%">
                    <Box display="flex" justifyContent="end" alignItems="center" mb="20px">
                        <Box component="img" src='/assets/alcago-logo.png' sx={{ height: "95px" }} />
                    </Box>
                    <Typography fontSize="36px" fontWeight={700} fontFamily="Poppins" color="#363B64">Alcago & {report.brand_name} Reklam Analizi</Typography>
                    <Box backgroundColor="white" mt="52px" pt="50px" pb="90px" px="10%" color="#A098AE">
                        <Typography fontSize="22px" fontWeight={500} fontFamily="Poppins">Sayın {report.brand_name + " Yetkilisi"}</Typography>
                        <Typography fontSize="22px" fontWeight={500} fontFamily="Poppins" mt="30px">Alcago olarak, dijital reklamcılık alanında devrim yaratma vizyonumuzla, siz değerli {report.brand_name} için özel bir demo gerçekleştirdik. Bu demo, Alcago platformunun hazır giyim sektöründeki firmalar için nasıl olağanüstü sonuçlar yaratabileceğinin canlı bir örneğidir. İşte, firmanızın Alcago ile gerçekleştirebileceği potansiyel başarı hikayesi:</Typography>
                    </Box>

                    <Box display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gap="20px" mt="52px" >
                        <Box display="flex" justifyContent="space-evenly" alignItems="center" gap="30px" backgroundColor="white" px="25px" py="40px" gridColumn={isSM || isXS ? "span 4" : isLG || isMD ? "span 2" : "span 1"} >
                            <Box p="20px" border="1px solid #DBDBDB">
                                <Box component="img" src='/assets/megaphone.png' sx={{ height: isMD ? "30px" : "45px" }} />
                            </Box>
                            <Box textAlign={"center"}>
                                <Typography fontSize="36px" fontWeight={700} fontFamily="Poppins" color="#363B64">1</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Toplam Reklam</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-evenly" alignItems="center" gap="30px" backgroundColor="white" px="25px" py="40px" gridColumn={isSM || isXS ? "span 4" : isLG || isMD ? "span 2" : "span 1"} >
                            <Box p="20px" border="1px solid #DBDBDB">
                                <Box component="img" src='/assets/show.png' sx={{ height: isMD ? "30px" : "45px" }} />
                            </Box>
                            <Box textAlign={"center"}>
                                <Typography fontSize="36px" fontWeight={700} fontFamily="Poppins" color="#363B64">{report.watchs && report.watchs.length > 0 ? report.watchs.length : 0}</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Toplam İzlenme</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-evenly" alignItems="center" gap="30px" backgroundColor="white" px="25px" py="40px" gridColumn={isSM || isXS ? "span 4" : isLG || isMD ? "span 2" : "span 1"} >
                            <Box p="20px" border="1px solid #DBDBDB">
                                <Box component="img" src='/assets/double-tap.png' sx={{ height: isMD ? "30px" : "45px" }} />
                            </Box>
                            <Box textAlign={"center"}>
                                <Typography fontSize="36px" fontWeight={700} fontFamily="Poppins" color="#363B64">{report.actions && report.actions.length > 0 ? report.actions.length : 0}</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Toplam Aksiyon</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-evenly" alignItems="center" gap="30px" backgroundColor="white" px="25px" py="40px" gridColumn={isSM || isXS ? "span 4" : isLG || isMD ? "span 2" : "span 1"} >
                            <Box p="20px" border="1px solid #DBDBDB">
                                <Box component="img" src='/assets/chat.png' sx={{ height: isMD ? "30px" : "45px" }} />
                            </Box>
                            <Box textAlign={"center"}>
                                <Typography fontSize="36px" fontWeight={700} fontFamily="Poppins" color="#363B64">{report.comments && report.comments.length > 0 ? report.comments.length : 0}</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Toplam Yorum</Typography>
                            </Box>
                        </Box>
                    </Box>


                    {
                        report.questions && report.questions.length > 0 &&
                        <Box display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))" gap="60px" mt="52px">
                            <Box backgroundColor="white" py={"42px"} gridColumn={isMD || isSM || isXS ? "span 3" : "span 2"}>
                                <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" ml="40px" color="#363B64">Reklamla İlgili Sorduğumuz Sorular</Typography>
                                <Box textAlign="center" mt="70px" px={"10%"}>
                                    <Typography fontSize="29px" fontWeight={400} fontFamily="Open Sans" color="#000000">{report.questions[0].question}</Typography>
                                    <Box component="hr" my="45px" />
                                    <Box display="flex" flexDirection="column" rowGap="20px" px={"20%"}>
                                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: report.questions[0].answer1 === report.questions[0].correct_answer ? "#108513" : "#851010", py: "18px" }} borderRadius="24px" >
                                            <Typography fontSize="29px" fontWeight={500} fontFamily="Oxanium" color="white">{report.questions[0].answer1}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: report.questions[0].answer2 === report.questions[0].correct_answer ? "#108513" : "#851010", py: "18px" }} borderRadius="24px" >
                                            <Typography fontSize="29px" fontWeight={500} fontFamily="Oxanium" color="white">{report.questions[0].answer2}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: report.questions[0].answer3 === report.questions[0].correct_answer ? "#108513" : "#851010", py: "18px" }} borderRadius="24px" >
                                            <Typography fontSize="29px" fontWeight={500} fontFamily="Oxanium" color="white">{report.questions[0].answer3}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box backgroundColor="white" py={"42px"} gridColumn={isMD || isSM || isXS ? "span 3" : "span 1"}>
                                <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" ml="40px" color="#363B64">Cevap Dağılımları</Typography>
                                <Box>

                                    <Box mt="42px" px={isMD || isSM || isXS ? "25%" : "10%"}>
                                        <div>&nbsp;</div>
                                        <Doughnut
                                            data={{
                                                labels: ['Yanlış Cevap', 'Doğru Cevap'],
                                                datasets: [{
                                                    label: 'Cevap Dağılımları',
                                                    data: [report.questions[0].false_answer_count, report.questions[0].true_answer_count],
                                                    backgroundColor: [
                                                        '#851010',
                                                        '#108513'
                                                    ],
                                                    datalabels: {
                                                        labels: {
                                                            title: "Yanlış Cevap",
                                                            value: {
                                                                color: ['white', "#108513"],
                                                                font: {
                                                                    size: 18,
                                                                    weight: "bold",
                                                                    family: "Poppins"
                                                                },
                                                                anchor: "center",
                                                                align: "center",
                                                                formatter: function (value, context) {
                                                                    return ((100 * value) / (context.chart.data.datasets[0].data[1] + value)) + "%"
                                                                }
                                                            }
                                                        },
                                                        color: ['#851010', "#108513"],
                                                    }
                                                }]
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        enabled: false
                                                    },
                                                    responsive: true,
                                                    maintainAspectRatio: true,
                                                },
                                                layout: {
                                                    padding: {
                                                        bottom(ctx) {
                                                            const chart = ctx.chart;
                                                            let pb = 0;
                                                            chart.data.datasets.forEach(function (el) {
                                                                const hOffset = el.hoverOffset || 0;
                                                                pb = Math.max(hOffset / 2 + 5, pb)
                                                            });
                                                            return pb;
                                                        },
                                                        top(ctx) {
                                                            const chart = ctx.chart;
                                                            let pt = 0;
                                                            chart.data.datasets.forEach(function (el) {
                                                                const hOffset = el.hoverOffset || 0;
                                                                pt = Math.max(hOffset / 2 + 5, pt)
                                                            });
                                                            return pt;
                                                        },
                                                        left(ctx) {
                                                            const chart = ctx.chart;
                                                            let pl = 0;
                                                            chart.data.datasets.forEach(function (el) {
                                                                const hOffset = el.hoverOffset || 0;
                                                                pl = Math.max(hOffset / 2 + 5, pl)
                                                            });
                                                            return pl;
                                                        },
                                                        right(ctx) {
                                                            const chart = ctx.chart;
                                                            let pr = 0;
                                                            chart.data.datasets.forEach(function (el) {
                                                                const hOffset = el.hoverOffset || 0;
                                                                pr = Math.max(hOffset / 2 + 5, pr)
                                                            });
                                                            return pr;
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </Box>

                                    <Box px="10%">
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mt="80px">
                                            <Box display="flex" justifyContent="center" alignItems="top" gap="25px">
                                                <Box sx={{ backgroundColor: "#108513", width: "25px", height: "25px" }} />
                                                <Typography fontSize="14px" fontWeight={400} fontFamily="Poppins" color="#363B64">Doğru Cevap (%{(100 * report.questions[0].true_answer_count) / (report.questions[0].false_answer_count + report.questions[0].true_answer_count)})</Typography>
                                            </Box>
                                            <Typography fontSize="18px" fontWeight={600} fontFamily="Poppins" color="#363B64" sx={{ ...(report.is_blurred === 1 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {}), userSelect: "none" }}>{report.questions[0].true_answer_count}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mt="24px">
                                            <Box display="flex" justifyContent="center" alignItems="top" gap="25px">
                                                <Box sx={{ backgroundColor: "#851010", width: "25px", height: "25px" }} />
                                                <Typography fontSize="14px" fontWeight={400} fontFamily="Poppins" color="#363B64" >Yanlış Cevap (%{(100 * report.questions[0].false_answer_count) / (report.questions[0].false_answer_count + report.questions[0].true_answer_count)})</Typography>
                                            </Box>
                                            <Typography fontSize="18px" fontWeight={600} fontFamily="Poppins" color="#363B64" sx={{ ...(report.is_blurred === 1 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {}), userSelect: "none" }}>{report.questions[0].false_answer_count}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }


                    <Box backgroundColor="white" px={isMD ? "40px" : isSM || isXS ? "20px" : "60px"} mt="52px">
                        <Box py="30px">
                            <Box display="flex" justifyContent="space-between" alignItems="top">
                                <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" color="#363B64">Reklam İstatistikleri</Typography>
                                <Box>
                                    <Select
                                        labelId="filter-label"
                                        label="Altyapı Sağlayıcısı"
                                        onChange={(e) => { setDateFilter(e.target.value) }}
                                        name="date_filter"
                                        value={dateFilter}
                                        sx={{ fontSize: isSM || isXS ? "10px" : "14px", fontFamily: "Poppins", width: isMD ? "100px" : isSM || isXS ? "65px" : "200px", textAlign: "center" }}
                                    >
                                        <MenuItem value={"all"} sx={{ fontSize: "14px", fontFamily: "Poppins" }}>Tümü</MenuItem>
                                        <MenuItem value={"30Days"} sx={{ fontSize: "14px", fontFamily: "Poppins" }}>30 Günlük</MenuItem>
                                        <MenuItem value={"7Days"} sx={{ fontSize: "14px", fontFamily: "Poppins" }}>7 Günlük</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent={isSM || isXS ? "space-between" : ""} gap="32px" mt="40px">
                                <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                                    <Box display="flex" alignItems="center" gap="10px">
                                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ border: "3px solid #F0C868", width: "15px", height: "15px", borderRadius: "50%" }} />
                                        <Typography fontSize="14px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Aksiyon Sayısı</Typography>
                                    </Box>
                                    <Typography fontSize="18px" fontWeight={700} fontFamily="Poppins" color="#363B64" >
                                        {report.groupedActions && report.groupedActions.length > 0 ? (dateFilter === "all" ? report.groupedActions.map((action) => action.count).reduce((a, b) => a + b, 0) : dateFilter === "30Days" ? report.groupedActions.slice(-30).map((action) => action.count).reduce((a, b) => a + b, 0) : report.groupedActions.slice(-7).map((action) => action.count).reduce((a, b) => a + b, 0)) : 0}
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                                    <Box display="flex" alignItems="center" gap="10px">
                                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ border: "3px solid #634F7A", width: "15px", height: "15px", borderRadius: "50%" }} />
                                        <Typography fontSize="14px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Gösterim Sayısı</Typography>
                                    </Box>
                                    <Typography fontSize="18px" fontWeight={700} fontFamily="Poppins" color="#363B64" >
                                        {report.groupedWatchs && report.groupedWatchs.length > 0 ? (dateFilter === "all" ? report.groupedWatchs.map((watch) => watch.count).reduce((a, b) => a + b, 0) : dateFilter === "30Days" ? report.groupedWatchs.slice(-30).map((watch) => watch.count).reduce((a, b) => a + b, 0) : report.groupedWatchs.slice(-7).map((watch) => watch.count).reduce((a, b) => a + b, 0)) : 0}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={isMD || isSM || isXS ? "0" : "42px"} pb={isMD || isSM || isXS ? "35px" : "65px"}>
                            <div>&nbsp;</div>
                            <Line data={{
                                labels: (dateFilter === "all" ? report.groupedActions.map((action) => action.statistic_date) : dateFilter === "30Days" ? report.groupedActions.slice(-30).map((action) => action.statistic_date) : report.groupedActions.slice(-7).map((action) => action.statistic_date)),
                                datasets: [{
                                    label: 'Aksiyon Sayısı',
                                    data: (dateFilter === "all" ? report.groupedActions.map((action) => action.count) : dateFilter === "30Days" ? report.groupedActions.slice(-30).map((action) => action.count) : report.groupedActions.slice(-7).map((action) => action.count)),
                                    fill: false,
                                    borderWidth: isMD ? 3 : isSM || isXS ? 2 : 4,
                                    borderColor: '#F0C868',
                                    tension: 0.1,
                                    datalabels: {
                                        display: false
                                    },
                                    pointStyle: 'circle',
                                    pointBackgroundColor: 'white',
                                    pointBorderColor: '#F0C868',
                                    pointBorderWidth: isMD ? 3 : isSM || isXS ? 2 : 4,
                                    pointRadius: isMD ? 6 : isSM || isXS ? 4 : 8
                                }, {
                                    label: 'Gösterim Sayısı',
                                    data: (dateFilter === "all" ? report.groupedWatchs.map((watch) => watch.count) : dateFilter === "30Days" ? report.groupedWatchs.slice(-30).map((watch) => watch.count) : report.groupedWatchs.slice(-7).map((watch) => watch.count)),
                                    fill: false,
                                    borderColor: '#634F7A',
                                    borderWidth: isMD ? 3 : isSM || isXS ? 2 : 4,
                                    tension: 0.1,
                                    datalabels: {
                                        display: false
                                    },
                                    pointStyle: 'circle',
                                    pointBackgroundColor: 'white',
                                    pointBorderColor: '#634F7A',
                                    pointBorderWidth: isMD ? 3 : isSM || isXS ? 2 : 4,
                                    pointRadius: isMD ? 6 : isSM || isXS ? 4 : 8
                                }]
                            }}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        tooltip: {
                                            enabled: true,
                                            titleAlign: "center",
                                            bodyAlign: "center",
                                            titleMarginBottom: 14,
                                            titleFont: {
                                                size: 18,
                                                weight: 700,
                                                family: "Poppins"
                                            },
                                            bodyFont: {
                                                size: 14,
                                                weight: 400,
                                                family: "Poppins"
                                            },
                                            footerFont: {
                                                size: 7,
                                                weight: 400,
                                                family: "Poppins"
                                            },
                                            boxHeight: 0,
                                            boxWidth: 0,
                                            boxPadding: 0,
                                            backgroundColor: "#363B64",
                                            titleSpacing: -5,
                                            callbacks: {
                                                label: function (context) {
                                                    return "   " + context.parsed.y + (context.dataset.label === "Aksiyon Sayısı" ? " Aksiyon" : " İzlenme") + "   ";
                                                },
                                                title: function (context) {
                                                    return "\n%" + (parseInt(context[0].dataset.data[context[0].dataIndex] * 100 / context[0].dataset.data.reduce((a, b) => a + b, 0)));
                                                },
                                                footer: function (context) {
                                                    return " ";
                                                }
                                            }
                                        },
                                        responsive: true,
                                        maintainAspectRatio: true
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: true,
                                                color: "#DBDBDB",
                                                lineWidth: isSM || isXS ? 1 : 3
                                            },
                                            ticks: {
                                                color: "#A098AE",
                                                font: {
                                                    weight: 400,
                                                    family: "Poppins"
                                                }
                                            },
                                            beginAtZero: true
                                        },
                                        y: {
                                            grid: {
                                                display: false
                                            },
                                            ticks: {
                                                color: "#A098AE",
                                                font: {
                                                    weight: 400,
                                                    family: "Poppins"
                                                },
                                                stepSize: 100
                                            },
                                            beginAtZero: true
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>

                    <Box display="grid" gridTemplateColumns="repeat(12, minmax(0, 1fr))" gap="50px" mt="52px">
                        <Box display="flex" justifyContent="space-between" gap="20px" alignItems="center" p="40px" backgroundColor="white" gridColumn={(isSM || isXS) ? "span 12" : report.coupon_given_ad === 1 ? "span 6" : "span 4 / -5"}>
                            <Box component="img" src='/assets/avarage-point.png' sx={{ height: isMD || isSM || isXS ? "100px" : isLG ? "150px" : "205px" }} />
                            <Box width="55%">
                                <Typography fontSize={isMD || isSM || isXS ? "14px" : isLG ? "15px" : "18px"} fontWeight={600} fontFamily="Poppins" color="#363B64">Puan Ortalaması</Typography>
                                <Box display="flex" justifyContent="center" alignItems="center" mt="48px" backgroundColor="#634F7A" padding={isMD || isSM || isXS ? "12px" : "18px"}>
                                    <Typography fontSize={isMD || isSM || isXS ? "18px" : isLG ? "21px" : "25px"} fontWeight={600} fontFamily="Poppins" color="white">{report.avarage_point}/5</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {report.coupon_given_ad === 1 &&
                            <Box position="relative" p="40px" pb="50px" overflow="hidden" backgroundColor="white" gridColumn={(isSM || isXS) ? "span 12" : "span 6"}>
                                <Box>
                                    <Typography fontSize={isMD || isSM || isXS ? "28px" : isLG ? "32px" : "36px"} fontWeight={700} fontFamily="Poppins" color="#363B64" sx={{ ...(report.is_blurred === 1 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {}), userSelect: "none" }}>{report.ad_like_count}</Typography>
                                    <Typography fontSize={isMD || isSM || isXS ? "14px" : isLG ? "15px" : "18px"} fontWeight={600} fontFamily="Poppins" color="#363B64" mt="10px">Reklam Beğeni Sayısı</Typography>
                                </Box>
                                <Box component="img" src='/assets/heart.png' position="absolute" right={0} bottom={-100} sx={{ height: "240px" }} />
                            </Box>
                        }
                    </Box>
                    <Box mt="150px">
                        <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" color="#363B64">Reklamın Aksiyon Haritası</Typography>
                        <Box backgroundColor="white" mt="16px">
                            <Box>
                                <Box sx={isSM || isXS ? { display: "none" } : {}}>
                                    <Box position="absolute" component="img" src='/assets/hand-image.svg' sx={{ width: isLG ? "125px" : isMD ? "90px" : "250px" }} mt={isLG ? "115px" : isMD ? "120px" : "190px"} left={0} right={isLG ? 210 : isMD ? 145 : 390} mx={"auto"} zIndex={15} />
                                    <Box position="absolute" component="img" src={report.bubble_image_link} sx={{ height: isLG ? "30px" : isMD ? "18px" : "50px", opacity: 0.7 }} mt={isLG ? "100px" : isMD ? "110px" : "175px"} left={0} right={isLG ? 90 : isMD ? 60 : 150} mx={"auto"} zIndex={10} />
                                    <Box position="absolute" component="img" src={report.ad_thumbnail_link} sx={{ height: isLG ? "100px" : isMD ? "70px" : "200px", borderRadius: "20px" }} mt={isMD ? "70px" : "55px"} left={0} right={0} mx={"auto"} />
                                </Box>
                                <div>&nbsp;</div>
                                <Bubble options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            ticks: { color: "grey", stepSize: 100 },
                                            grid: {
                                                color: "grey"
                                            },
                                            beginAtZero: true, max: report.is_vertical === 1 ? 1920 : 1080
                                        },
                                        x: {
                                            ticks: { color: "grey", stepSize: 100 },
                                            grid: {
                                                color: "grey"
                                            },
                                            beginAtZero: true, max: report.is_vertical === 1 ? 1080 : 1920
                                        }
                                    }
                                }}
                                    data={{
                                        labels: ["x kordinatı", "y kordinatı"],
                                        datasets: [
                                            {
                                                label: 'Patlatılan Baloncuk',
                                                backgroundColor: "rgba(40, 161, 44, 0.35)",
                                                data: report.actions && report.actions.length > 0 ? report.actions.filter((act) => act.action_name === "Patlatılan Baloncuk").map((act) => ({ x: act.x_coordinate, y: act.y_coordinate })) : [],
                                                pointRadius: 10,
                                                datalabels: {
                                                    display: false
                                                }
                                            },
                                            {
                                                label: 'Patlatılmayan Baloncuk',
                                                backgroundColor: "rgba(186, 22, 22, 0.35)",
                                                data: report.actions && report.actions.length > 0 ? report.actions.filter((act) => act.action_name === "Patlatılmayan Baloncuk").map((act) => ({ x: act.x_coordinate, y: act.y_coordinate })) : [],
                                                pointRadius: 10,
                                                datalabels: {
                                                    display: false
                                                }
                                            }, {
                                                label: 'Patlatılmayan Bomba',
                                                backgroundColor: "rgba(40, 161, 44, 0.35)",
                                                data: report.actions && report.actions.length > 0 ? report.actions.filter((act) => act.action_name === "Patlatılmayan Bomba").map((act) => ({ x: act.x_coordinate, y: act.y_coordinate })) : [],
                                                pointRadius: 10,
                                                datalabels: {
                                                    display: false
                                                }
                                            }, {
                                                label: 'Patlatılan Bomba',
                                                backgroundColor: "rgba(186, 22, 22, 0.35)",
                                                data: report.actions && report.actions.length > 0 ? report.actions.filter((act) => act.action_name === "Patlatılan Bomba").map((act) => ({ x: act.x_coordinate, y: act.y_coordinate })) : [],
                                                pointRadius: 10,
                                                datalabels: {
                                                    display: false
                                                }
                                            }
                                        ],
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box px="10%" mt="110px">
                    <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" color="#363B64">Reklam Kampanyasının Şehirsel Dağılımı</Typography>
                </Box>
                <Box px="3%">
                    {geoData.length > 0 &&
                        <ComposableMap
                            projection="geoMercator"
                            projectionConfig={{
                                scale: 2300,
                                center: [35.168953, 39.933365]
                            }}
                            height={420}
                        >
                            <Geographies
                                geography={turTopo}
                                stroke="#DBDBDB"
                                strokeWidth={0.5}
                            >
                                {({ geographies, projection }) =>
                                    geographies.map((geo) => {

                                        // const onGeoEventFactory = (handleCoordinates) => {
                                        //     const gPath = geoPath().projection(projection);

                                        //     return (event) => {
                                        //         const dim = event.target.getBoundingClientRect();
                                        //         const cx = event.clientX - dim.left;
                                        //         const cy = event.clientY - dim.top;

                                        //         const [geoX, geoY] = gPath.bounds([cx, cy]);

                                        //         console.log(event.nativeEvent)
                                        //         const svg = event.nativeEvent.target

                                        //         const adjustScale = 0

                                        //         const clickCoordsInsideSvg = [
                                        //             geoX + (cx / adjustScale),
                                        //             geoY + (cy / adjustScale)
                                        //         ];

                                        //         handleCoordinates(projection.invert(clickCoordsInsideSvg));
                                        //     }
                                        // }

                                        return (<Geography key={geo.rsmKey}
                                            geography={geo}
                                            onMouseEnter={
                                                (e) => {
                                                    setToolTipInfo({
                                                        name: geo.properties.name,
                                                        value: geoData.find(city => city[0] === geo.properties.name)[1],
                                                        x: e.clientX,
                                                        y: e.clientY
                                                    })
                                                }
                                            }
                                            onMouseDown={
                                                (e) => {
                                                    setToolTipInfo({
                                                        name: geo.properties.name,
                                                        value: geoData.find(city => city[0] === geo.properties.name)[1],
                                                        x: e.clientX,
                                                        y: e.clientY
                                                    })
                                                }
                                            }
                                            onMouseLeave={() => {
                                                setToolTipInfo(null)
                                            }}
                                            style={{
                                                default: {
                                                    fill: "#634F7A",
                                                    outline: "none"
                                                },
                                                hover: {
                                                    fill: "#392C4C",
                                                    outline: "none"
                                                },
                                                pressed: {
                                                    fill: "#634F7A",
                                                    outline: "none"
                                                }
                                            }} />)
                                    }
                                    )
                                }
                            </Geographies>
                            {/** Ankara, Trabzon, Çanakkale, Diyarbakır, Antalya */}
                            {cities
                                .filter((ct) => ct.name === "Ankara" || ct.name === "Trabzon" || ct.name === "Çanakkale" || ct.name === "Diyarbakır" || ct.name === "Antalya")
                                .map(({ name, latitude, longitude }) => (
                                    <Marker key={name} coordinates={[longitude, latitude]}>
                                        <text
                                            textAnchor="middle"
                                            fontSize="13px"
                                            style={{ ...(report.is_blurred === 1 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {}), fontFamily: "Poppins", fill: "white", userSelect: "none", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none" }}
                                            onMouseEnter={
                                                (e) => {
                                                    setToolTipInfo({
                                                        name: name,
                                                        value: geoData.find(city => city[0] === name)[1],
                                                        x: e.clientX,
                                                        y: e.clientY
                                                    })
                                                }
                                            }
                                            onMouseDown={
                                                (e) => {
                                                    setToolTipInfo({
                                                        name: name,
                                                        value: geoData.find(city => city[0] === name)[1],
                                                        x: e.clientX,
                                                        y: e.clientY
                                                    })
                                                }
                                            }
                                            onMouseLeave={() => {
                                                setToolTipInfo(null)
                                            }}
                                        >
                                            {
                                                geoData.find(
                                                    city => city && city[0] && city[0].toLowerCase() === name.toLowerCase()
                                                ) ? geoData.find(
                                                    city => city && city[0] && city[0].toLowerCase() === name.toLowerCase()
                                                )[1] : 0
                                            }
                                        </text>
                                    </Marker>
                                ))}
                        </ComposableMap>
                    }
                </Box>

                <Box px="10%" mt="110px">
                    <Box backgroundColor="white" p="90px 50px 90px 50px">
                        <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" color="#363B64" pb="0">Dağılımlar</Typography>
                        <Box display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gap="10px">
                            <WatchDistributionDougnutChart
                                title="Cinsiyet Dağılımı"
                                data={{
                                    labels: ['Erkek', 'Kadın', 'Belirtilmemiş'],
                                    datasets: [{
                                        label: 'Cinsiyet Dağılımı',
                                        data: [(report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.gender === "Erkek").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.gender === "Kadın").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.gender === "Belirtmek İstemiyorum").length : 0)],
                                        backgroundColor: [
                                            '#6D92E9',
                                            '#E96DB7',
                                            '#E9D86D'
                                        ],
                                        datalabels: {
                                            labels: {
                                                title: ""
                                            }
                                        }
                                    }]
                                }}
                                legends={[
                                    {
                                        color: "#6D92E9",
                                        text: "Erkek",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.gender === "Erkek").length > 0 ? (100 * (report.watchs.filter(w => w.gender === "Erkek").length)) / ((report.watchs.filter(w => w.gender === "Erkek").length) + (report.watchs.filter(w => w.gender !== "Erkek").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E96DB7",
                                        text: "Kadın",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.gender === "Kadın").length > 0 ? (100 * (report.watchs.filter(w => w.gender === "Kadın").length)) / ((report.watchs.filter(w => w.gender === "Kadın").length) + (report.watchs.filter(w => w.gender !== "Kadın").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E9D86D",
                                        text: "Diğer",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.gender === "Belirtmek İstemiyorum").length > 0 ? (100 * (report.watchs.filter(w => w.gender === "Belirtmek İstemiyorum").length)) / ((report.watchs.filter(w => w.gender === "Belirtmek İstemiyorum").length) + (report.watchs.filter(w => w.gender !== "Belirtmek İstemiyorum").length)) : 0 : 0)
                                    }
                                ]}
                            />
                            <WatchDistributionDougnutChart
                                title="Medeni Durum Dağılımı"
                                data={{
                                    labels: ['Bekâr', 'Evli'],
                                    datasets: [{
                                        label: 'Medeni Durum Dağılımı',
                                        data: [(report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.marital_status === "Bekâr").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.marital_status === "Evli").length : 0)],
                                        backgroundColor: [
                                            '#E9D86D',
                                            '#E96D6D'
                                        ],
                                        datalabels: {
                                            labels: {
                                                title: ""
                                            }
                                        }
                                    }]
                                }}
                                legends={[
                                    {
                                        color: "#E9D86D",
                                        text: "Bekâr",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.marital_status === "Bekâr").length > 0 ? (100 * (report.watchs.filter(w => w.marital_status === "Bekâr").length)) / ((report.watchs.filter(w => w.marital_status === "Bekâr").length) + (report.watchs.filter(w => w.marital_status !== "Bekâr").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E96D6D",
                                        text: "Evli",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.marital_status === "Evli").length > 0 ? (100 * (report.watchs.filter(w => w.marital_status === "Evli").length)) / ((report.watchs.filter(w => w.marital_status === "Evli").length) + (report.watchs.filter(w => w.marital_status !== "Evli").length)) : 0 : 0)
                                    }
                                ]}
                            />

                            <WatchDistributionDougnutChart
                                title="Öğrenim Durum Dağılımı"
                                data={{
                                    labels: ['Lisans', 'Yüksek Lisans', 'Lise'],
                                    datasets: [{
                                        label: 'Öğrenim Durum Dağılımı',
                                        data: [(report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.education === "Lisans").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.education === "Yüksek Lisans").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.education === "Lise").length : 0)],
                                        backgroundColor: [
                                            '#E9D86D',
                                            '#E96D6D',
                                            '#6D75E9'
                                        ],
                                        datalabels: {
                                            labels: {
                                                title: ""
                                            }
                                        }
                                    }]
                                }}
                                legends={[
                                    {
                                        color: "#6D92E9",
                                        text: "Lisans",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.education === "Lisans").length > 0 ? (100 * (report.watchs.filter(w => w.education === "Lisans").length)) / ((report.watchs.filter(w => w.education === "Lisans").length) + (report.watchs.filter(w => w.education !== "Lisans").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E96DB7",
                                        text: "Yüksek Lisans",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.education === "Yüksek Lisans").length > 0 ? (100 * (report.watchs.filter(w => w.education === "Yüksek Lisans").length)) / ((report.watchs.filter(w => w.education === "Yüksek Lisans").length) + (report.watchs.filter(w => w.education !== "Yüksek Lisans").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E9D86D",
                                        text: "Lise",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.education === "Lise").length > 0 ? (100 * (report.watchs.filter(w => w.education === "Lise").length)) / ((report.watchs.filter(w => w.education === "Lise").length) + (report.watchs.filter(w => w.education !== "Lise").length)) : 0 : 0)
                                    }
                                ]}
                            />
                            <WatchDistributionDougnutChart
                                title="Maddi Durum Dağılımı"
                                data={{
                                    labels: ['10.000₺ Altı', '10.000₺ - 50.000₺ Arası', '50.000₺ Üstü'],
                                    datasets: [{
                                        label: 'Maddi Durum Dağılımı',
                                        data: [(report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.income_status === "10.000₺'nin altında").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.income_status === "10.000₺ - 20.000₺" || w.income_status === "20.000₺ - 50.000₺").length : 0), (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.income_status === "50.000₺'nin üstünde").length : 0)],
                                        backgroundColor: [
                                            '#E9D86D',
                                            '#6D92E9',
                                            '#E96DB7'
                                        ],
                                        datalabels: {
                                            labels: {
                                                title: ""
                                            }
                                        }
                                    }]
                                }}
                                legends={[
                                    {
                                        color: "#6D92E9",
                                        text: "10.000₺ Altı",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.income_status === "10.000₺'nin altında").length > 0 ? (100 * (report.watchs.filter(w => w.income_status === "10.000₺'nin altında").length)) / ((report.watchs.filter(w => w.income_status === "10.000₺'nin altında").length) + (report.watchs.filter(w => w.income_status !== "10.000₺'nin altında").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E96DB7",
                                        text: "10.000₺ - 50.000₺ Arası",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.income_status === "10.000₺ - 20.000₺" || w.income_status === "20.000₺ - 50.000₺").length > 0 ? (100 * (report.watchs.filter(w => w.income_status === "10.000₺ - 20.000₺" || w.income_status === "20.000₺ - 50.000₺").length)) / ((report.watchs.filter(w => w.income_status === "10.000₺ - 20.000₺" || w.income_status === "20.000₺ - 50.000₺").length) + (report.watchs.filter(w => w.income_status !== "10.000₺ - 20.000₺" || w.income_status === "20.000₺ - 50.000₺").length)) : 0 : 0)
                                    },
                                    {
                                        color: "#E9D86D",
                                        text: "50.000₺ Üstü",
                                        percentage: (report.watchs && report.watchs.length > 0 ? report.watchs.filter(w => w.income_status === "50.000₺'nin üstünde").length > 0 ? (100 * (report.watchs.filter(w => w.income_status === "50.000₺'nin üstünde").length)) / ((report.watchs.filter(w => w.income_status === "50.000₺'nin üstünde").length) + (report.watchs.filter(w => w.income_status !== "50.000₺'nin üstünde").length)) : 0 : 0)
                                    }
                                ]}
                            />
                        </Box>
                    </Box>

                    {
                        report.comments && report.comments.length > 0 &&
                        <Box mt="65px">
                            <Typography fontSize="24px" fontWeight={700} fontFamily="Poppins" color="#363B64">Örnek Yorumlar</Typography>
                            <Box display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))" justifyContent="space-between" alignItems="center" gap="40px" mt="40px">
                                {
                                    report.comments.map((comment, index) => {
                                        if (index < 3) {
                                            return (
                                                <Box key={comment.id} backgroundColor="white" p="32px" gridColumn={isMD || isSM || isXS ? "span 3" : "span 1"}>
                                                    <Box display="flex" alignItems="center" gap="15px">
                                                        <Box component="img" src={comment.user_profile_photo_link} sx={{ ...(report.is_blurred === 1 && index === 2 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {}), height: "50px", width: "50px" }} />
                                                        <Box mt="5px">
                                                            <Typography fontSize="18px" fontWeight={600} fontFamily="Poppins" color="#363B64" sx={(report.is_blurred === 1 && index === 2 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {})}>{comment.username}</Typography>
                                                            <Box display="flex" >
                                                                <Box component="img" src='/assets/star.png' sx={{ height: "16px" }} />
                                                                <Typography fontSize="14px" fontWeight={400} fontFamily="Poppins" color="#A098AE">{comment.point.toFixed(1)}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box height="40px">
                                                        <Typography fontSize="14px" fontWeight={400} fontFamily="Poppins" color="#363B64" mt="20px" sx={(report.is_blurred === 1 && index === 2 ? { filter: "blur(4px)", WebkitFilter: "blur(4px)" } : {})}>{comment.comment}</Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    })
                                }
                            </Box>
                        </Box>
                    }

                    <Box textAlign="center">
                        <Typography fontSize="36px" fontWeight={700} fontFamily="Poppins" color="#634F7A" mt="80px" overflow="clip">Durum Değerlendirmesi</Typography>
                    </Box>
                    <Box display="grid" gridTemplateColumns="repeat(9, minmax(0, 1fr))" rowGap="65px" mt="40px">
                        <Box backgroundColor="white" gridColumn={isMD || isSM || isXS ? "span 9" : "span 4"} display="flex" flexDirection={(isXS || isSM) ? "column" : "row"} alignItems="center" justifyContent="center" gap="60px" padding="35px">
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box p={isLG ? "27px" : "35px"} border="1px solid #DBDBDB">
                                    <Box component="img" src='/assets/show-purple.png' sx={{ height: isLG ? "60px" : "90px" }} />
                                </Box>
                                <Typography fontSize={isLG ? "27px" : "36px"} fontWeight={700} fontFamily="Poppins" color="#634F7A" mt="40px">{report.watchs && report.watchs.length > 0 ? report.watchs.length : 0}</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="10px" textAlign="center">Toplam İzlenme</Typography>
                            </Box>
                            <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Firmanızın reklam videosu, Alcago platformunda {report.watchs && report.watchs.length > 0 ? report.watchs.length : 0} görüntüleme aldı, bu da geniş bir kitleye ulaşımınızı gösteriyor.</Typography>
                        </Box>

                        <Box backgroundColor="white" gridColumn={isMD || isSM || isXS ? "span 9" : "span 4 / -1"} display="flex" alignItems="center" flexDirection={(isXS || isSM) ? "column" : "row"} justifyContent="center" gap="60px" padding="45px">
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box p={isLG ? "27px" : "35px"} border="1px solid #DBDBDB">
                                    <Box component="img" src='/assets/double-tap-purple.png' sx={{ height: isLG ? "60px" : "90px" }} />
                                </Box>
                                <Typography fontSize={isLG ? "27px" : "36px"} fontWeight={700} fontFamily="Poppins" color="#634F7A" mt="40px">{report.actions && report.actions.length > 0 ? report.actions.length : 0}</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="10px" textAlign="center">Toplam Aksiyon</Typography>
                            </Box>
                            <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Bu görüntülemeler, {report.actions && report.actions.length > 0 ? report.actions.length : 0} reklam içi aksiyona dönüştü, kullanıcıların reklamınızla etkileşime geçtiğini ve ilgi gösterdiğini kanıtlıyor.</Typography>
                        </Box>

                        <Box backgroundColor="white" gridColumn={isMD || isSM || isXS ? "span 9" : "span 7 / -2"} display="flex" flexDirection={(isXS || isSM) ? "column" : "row"} alignItems="center" justifyContent="center" gap="52px" padding="30px">
                            <Box position="relative">
                                <Box position="relative" component="img" src='/assets/progress-background.png' sx={{ height: isMD || isSM || isXS ? "200px" : "280px" }} />
                                <Typography fontSize={isMD || isSM || isXS ? "40px" : "50px"} fontWeight={700} fontFamily="Poppins" color="#634F7A" position="absolute" top={isMD || isSM || isXS ? "72px" : "105px"} left={report.coupon_distribution_percentage === 100 ? isMD || isSM || isXS ? "50px" : "80px" : report.coupon_distribution_percentage < 10 ? isMD || isSM || isXS ? "75px" : "100px" : isMD || isSM || isXS ? "58px" : "90px"}>%{report.coupon_distribution_percentage}</Typography>
                            </Box>

                            <Box>
                                <Typography fontSize="25px" fontWeight={700} fontFamily="Poppins" color="#634F7A">Kupon Dağılımı</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="14px">Bu etkileşimler, potansiyel olarak %{report.coupon_distribution_percentage} oranında kupon dağılımına yol açabilir. Bu, müşterilerinizin satın alma yolculuğunda önemli bir adım.</Typography>
                            </Box>
                        </Box>

                        {report.promotion_campaign !== 1 &&
                            <>
                                <Box backgroundColor="white" position="relative" overflow="hidden" gridColumn={isMD || isSM || isXS ? "span 9" : "span 4"} display="flex" flexDirection={(isXS || isSM) ? "column" : "row"} alignItems="center" justifyContent="center" gap="60px" padding="45px">
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box p={isLG ? "27px" : "35px"} border="1px solid #DBDBDB">
                                            <Box component="img" src='/assets/coupon.png' sx={{ height: isLG ? "60px" : "90px" }} />
                                        </Box>
                                        <Typography fontSize={isLG ? "27px" : "36px"} fontWeight={700} fontFamily="Poppins" color="#634F7A" mt="40px">{report.coupon_distribution_percentage * (report.watchs && report.watchs.length > 0 ? report.watchs.length : 0) / 100}</Typography>
                                        <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="10px" textAlign="center">Kupon Alımı</Typography>
                                    </Box>
                                    <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">Etkileşimler sonucunda, tahmini {report.coupon_distribution_percentage * (report.watchs && report.watchs.length > 0 ? report.watchs.length : 0) / 100} kupon alımı gerçekleşir ve bu da {report.conversion_percentage}'lik bir dönüşüm oranıyla {report.conversion_percentage * (report.coupon_distribution_percentage * (report.watchs && report.watchs.length > 0 ? report.watchs.length : 0) / 100) / 100} sepet oluşturur.</Typography>
                                    <Box component="img" src='/assets/cart-oppacity.png' position="absolute" right={0} bottom={-45} sx={{ height: "240px" }} />
                                </Box>

                                <Box backgroundColor="white" position="relative" overflow="hidden" gridColumn={isMD || isSM || isXS ? "span 9" : "span 4 / -1"} display={"flex"} flexDirection={(isXS || isSM) ? "column" : "row"} alignItems="center" justifyContent="center" gap="60px" padding="45px">
                                    <Box display={"flex"} flexDirection="column" alignItems="center">
                                        <Box p={isLG ? "27px" : "35px"} border="1px solid #DBDBDB">
                                            <Box component="img" src='/assets/cart.png' sx={{ height: isLG ? "60px" : "90px" }} />
                                        </Box>
                                        <Typography fontSize={isLG ? "27px" : "36px"} fontWeight={700} fontFamily="Poppins" color="#634F7A" mt="40px">{report.avarage_basket_amount} TL</Typography>
                                        <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="10px" textAlign="center">Sepet Tutarı</Typography>
                                    </Box>
                                    <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE">{report.sector_name} sektöründe ortalama sepet tutarını {report.avarage_basket_amount} TL olarak hesapladığımızda, bu kampanya ile <b>{report.avarage_basket_amount * report.conversion_percentage * (report.coupon_distribution_percentage * (report.watchs && report.watchs.length > 0 ? report.watchs.length : 0) / 100) / 100} TL</b>'lik bir ciro elde etme potansiyeliniz var.</Typography>
                                    <Box component="img" src='/assets/money.png' position="absolute" right={0} bottom={-70} sx={{ height: "240px" }} />
                                </Box>
                            </>
                        }

                        <Box backgroundColor="white" gridColumn="span 9" display="flex" alignItems="center" justifyContent="center" gap="110px" p="55px">
                            {!(isSM || isXS) &&
                                <Box component="img" src='/assets/check-mark.png' sx={{ width: isXL ? "280px" : isLG ? "200px" : isMD ? "150px" : "150px" }} />
                            }
                            <Box>
                                <Typography fontSize="25px" fontWeight={700} fontFamily="Poppins" color="#634F7A">Alcago ile Gerçekleşebilecek Başarı</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="14px">Bu demo, Alcago'nun sizin gibi markalar için nasıl değer yaratabileceğinin somut bir kanıtıdır. Platformumuz, reklamınızın sadece görülmesini değil, aynı zamanda etkileşim ve dönüşüm yaratmasını sağlayarak, marka bilinirliğinizi artırır ve cironuzu önemli ölçüde yükseltir.</Typography>
                            </Box>
                        </Box>

                        {report.advices &&
                            <Box backgroundColor="white" gridColumn="span 9" display="flex" alignItems="center" justifyContent="center" gap="110px" p="55px" >
                                {!(isSM || isXS) &&
                                    <Box component="img" src={report.brand_logo_link} sx={{ width: isXL ? "280px" : isLG ? "200px" : isMD ? "150px" : "150px" }} />
                                }
                                <Box>
                                    <Typography fontSize="25px" fontWeight={700} fontFamily="Poppins" color="#634F7A">Kampanya İvmeniz</Typography>
                                    <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="14px">Bu potansiyel sonuçlardan etkilendiyseniz ve Alcago ile kampanyalarınızı nasıl zenginleştirebileceğinizi görmek istiyorsanız, lütfen bizimle iletişime geçin. Firmanız için nasıl değer yaratabileceğimizi detaylıca konuşmak üzere bir görüşme ayarlayabiliriz.</Typography>
                                </Box>
                            </Box>
                        }

                        <Box backgroundColor="white" gridColumn="span 9" display="flex" alignItems="center" justifyContent="center" gap="110px" p="55px">
                            {!(isSM || isXS) &&
                                <Box component="img" src='/assets/alcago-logo-140.png' sx={{ width: isXL ? "280px" : isLG ? "200px" : isMD ? "150px" : "150px" }} />
                            }
                            <Box>
                                <Typography fontSize="25px" fontWeight={700} fontFamily="Poppins" color="#634F7A">{report.advices ? "Alcago Uzmanlarının Tavsiyeleri" : "Demo Talebi"}</Typography>
                                <Typography fontSize="18px" fontWeight={400} fontFamily="Poppins" color="#A098AE" mt="14px">{report.advices ? report.advices : "Bu demo, Alcago'nun sizin gibi markalar için nasıl değer yaratabileceğinin somut bir kanıtıdır. Platformumuz, reklamınızın sadece görülmesini değil, aynı zamanda etkileşim ve dönüşüm yaratmasını sağlayarak, marka bilinirliğinizi artırır ve cironuzu önemli ölçüde yükseltir."}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" sx={{ backgroundColor: "#634F7A", color: "white", padding: "15px 115px", mt: "80px", textTransform: "none", '&:hover': { backgroundColor: "#665579" } }}>
                        <Typography
                            fontSize="25px"
                            fontWeight={600}
                            fontFamily="Poppins"
                            onClick={
                                () => {
                                    if (report.generated_by_alcago === 1) {
                                        //https://alcago.com/demo-talebi/
                                        window.open("https://alcago.com/demo-talebi/", "_blank")
                                    } else {
                                        //https://alcago.com/iletisim/
                                        window.open("https://alcago.app/brand-panel/ads/", "_blank")
                                    }
                                }
                            }>{report.generated_by_alcago === 1 ? "Bize Ulaşın" : "Yeni Kampanya Ekle"}</Typography>
                    </Button>
                </Box>
            </Box>
        )
    } else {
        return ""
    }
}


function WatchDistributionDougnutChart({ title, data, legends }) {
    const isXL = useMediaQuery({ query: '(min-width: 1440px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1024px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    return (
        <Box display="flex" alignItems="center" gap="10px" gridColumn={isLG || isMD ? "span 2" : isSM || isXS ? "span 4" : "span 1"}>
            <Box >
                <Box mt="42px" width={"200px"}>
                    <div>&nbsp;</div>
                    <Doughnut
                        data={data}
                        options={{
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                                responsive: true,
                                maintainAspectRatio: true,
                            },
                            layout: {
                                padding: {
                                    bottom(ctx) {
                                        const chart = ctx.chart;
                                        let pb = 0;
                                        chart.data.datasets.forEach(function (el) {
                                            const hOffset = el.hoverOffset || 0;
                                            pb = Math.max(hOffset / 2 + 5, pb)
                                        });
                                        return pb;
                                    },
                                    top(ctx) {
                                        const chart = ctx.chart;
                                        let pt = 0;
                                        chart.data.datasets.forEach(function (el) {
                                            const hOffset = el.hoverOffset || 0;
                                            pt = Math.max(hOffset / 2 + 5, pt)
                                        });
                                        return pt;
                                    },
                                    left(ctx) {
                                        const chart = ctx.chart;
                                        let pl = 0;
                                        chart.data.datasets.forEach(function (el) {
                                            const hOffset = el.hoverOffset || 0;
                                            pl = Math.max(hOffset / 2 + 5, pl)
                                        });
                                        return pl;
                                    },
                                    right(ctx) {
                                        const chart = ctx.chart;
                                        let pr = 0;
                                        chart.data.datasets.forEach(function (el) {
                                            const hOffset = el.hoverOffset || 0;
                                            pr = Math.max(hOffset / 2 + 5, pr)
                                        });
                                        return pr;
                                    }
                                }
                            },
                            cutout: "75%"
                        }}
                    />
                </Box>
                <Typography fontSize="18px" fontWeight={600} fontFamily="Poppins" color="#363B64" textAlign="center">{title}</Typography>
            </Box>

            <Box>
                {legends && legends.map(legend =>
                    <Box my="10px" key={legend.text}>
                        <Box display="flex" alignItems="top" justifyContent="space-between" overflow="clip" >
                            <Box backgroundColor={legend.color} width="10px" height="10px" mt="3px" borderRadius="20px" />
                            <Box ml="5px" display="flex" flexDirection="column" alignItems="end">
                                <Typography fontSize="9px" fontWeight={400} fontFamily="Poppins" color="#8B8B91" textAlign="end">{legend.text}</Typography>
                                <Typography fontSize="13px" fontWeight={400} fontFamily="Poppins" color="#47474D" >{parseInt(legend.percentage)}%</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box >
    )
}


export default ReportsPage