import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useContext, useState } from 'react';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import axios from 'axios';
import { useEffect } from 'react';
import AdDialog from '../../adminPanel/ads/adDialog';
import AdBox from '../../../../components/AdBox';
import InfiniteScroll from 'react-infinite-scroll-component';

function Ads() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const [ads, setAds] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchAds(0);
  }

  const fetchAds = (startIndex) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdsWithBrandId?token=${token}&brandId=${user.id}&startIndex=${startIndex}&limit=10`, {
      headers: {
        "content-type": "application/json"
      },
    })
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setHasMore(response.data.ads && response.data.ads.length === 10);
          if (startIndex === 0) {
            setAds([...response.data.ads]);
          } else {
            setAds((prev) => [...prev, ...response.data.ads]);
          }
        }
      });
  }

  useEffect(() => { fetchAds(0) }, [])

  return (
    <Box m="20px 0 20px 20px">
      <Box>
        <Header title="Reklamlar" subtitle="Reklamlar sayfasına hoşgeldiniz" />
      </Box>
      <Box>
        <Box mb="20px" ml="10px">
          <Button sx={{ background: colors.primary[400], borderRadius: "10px", p: "10px" }} startIcon={<AddCircleOutlineOutlinedIcon sx={{ color: colors.greenAccent[400], width: "30px", height: "30px" }} />} onClick={() => { setOpenDialog(true) }}>
            <Typography variant='h6' color={colors.greenAccent[400]}>Yeni Reklam Ekle</Typography>
          </Button>
        </Box>

        <Box mt="10px">
          <InfiniteScroll
            dataLength={ads.length}
            next={() => fetchAds(ads.length)}
            hasMore={hasMore}
            loader={<h4>Yükleniyor...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Tüm reklamları görüntülüyorsunuz.</b>
              </p>
            }
          >
            {ads.map((ad) =>
              <AdBox
                key={ad.id}
                id={ad.id}
                coupon_title={ad.coupon_title}
                has_coupons={ad.has_coupons}
                promotion_campaign={ad.promotion_campaign}
                discount_percentage={ad.discount_percentage}
                watch_count={ad.watch_count}
                last_usage_date={ad.last_usage_date}
                thumbnail_link={ad.thumbnail_image_link}
                video_link={ad.video_link}
                is_active={ad.is_active}
              />
            )}
          </InfiniteScroll>
        </Box>
      </Box>
      <AdDialog open={openDialog} handleClose={handleDialogClose} type={"add"} isAdmin={false} selectedAd={{ brand_id: user.id, brand_name: user.name }} />
    </Box>

  )
}

export default Ads